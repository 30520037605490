// MaterialityMatrix.js
import React from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const MaterialityMatrix = ({ gameState }) => {
  // Dati per la matrice di materialità basati sullo stato del gioco
  const data = [
    { name: 'Emissioni', impattoInterno: gameState.emissions, impattoEsterno: 80 },
    { name: 'Reputazione', impattoInterno: gameState.reputation, impattoEsterno: 60 },
    { name: 'Ricerca e Sviluppo', impattoInterno: gameState.researchPoints, impattoEsterno: 50 },
    // Aggiungi altre variabili ESG in base allo stato di gameState
  ];

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ScatterChart
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      >
        <CartesianGrid />
        <XAxis type="number" dataKey="impattoInterno" name="Impatto Interno" label={{ value: 'Impatto Interno', position: 'insideBottom', offset: -10 }} />
        <YAxis type="number" dataKey="impattoEsterno" name="Impatto Esterno" label={{ value: 'Impatto Esterno', angle: -90, position: 'insideLeft' }} />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
        <Legend />
        <Scatter name="Variabili ESG" data={data} fill="#8884d8" />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default MaterialityMatrix;
