import React, { useState } from 'react';
import { Card, Typography, Button, Modal, InputNumber, message } from 'antd';

const Market = ({ gameState, sellEnergy, calculatePrice }) => {
  const [sellAmount, setSellAmount] = useState(0);
  const [isRenewableModalVisible, setIsRenewableModalVisible] = useState(false);
  const [isFossilModalVisible, setIsFossilModalVisible] = useState(false);

  // Prezzi dinamici basati sulla funzione calculatePrice
  const currentPriceRenewable = calculatePrice('renewable');
  const currentPriceFossil = calculatePrice('fossil');

  const handleSell = (type) => {
    let availableEnergy = type === 'renewable' ? gameState.energyProduction.renewable : gameState.energyProduction.fossil;
    let currentPrice = type === 'renewable' ? currentPriceRenewable : currentPriceFossil;

    if (sellAmount > availableEnergy) {
      message.error(`Quantità di energia ${type === 'renewable' ? 'rinnovabile' : 'fossile'} insufficiente.`);
      return;
    }

    sellEnergy(sellAmount, currentPrice, type);
    message.success(`Hai venduto ${sellAmount} unità di energia ${type === 'renewable' ? 'rinnovabile' : 'fossile'} a €${currentPrice} ciascuna.`);
    setSellAmount(0);

    if (type === 'renewable') {
      setIsRenewableModalVisible(false);
    } else {
      setIsFossilModalVisible(false);
    }
  };

  return (
    <div>
      <Card title="Mercato Energetico">
        <Typography.Paragraph>
          Vendi l'energia prodotta sul mercato.
        </Typography.Paragraph>

        <Typography.Paragraph>
          Prezzo energia rinnovabile: €{currentPriceRenewable} per unità.
        </Typography.Paragraph>
        <Button type="primary" onClick={() => setIsRenewableModalVisible(true)} style={{ marginBottom: 16 }}>
          Vendi Energia Rinnovabile
        </Button>

        <Typography.Paragraph>
          Prezzo energia fossile: €{currentPriceFossil} per unità.
        </Typography.Paragraph>
        <Button type="primary" onClick={() => setIsFossilModalVisible(true)}>
          Vendi Energia Fossile
        </Button>
      </Card>

      {/* Modal per vendere energia rinnovabile */}
      <Modal
        title="Vendi Energia Rinnovabile"
        visible={isRenewableModalVisible}
        onCancel={() => setIsRenewableModalVisible(false)}
        onOk={() => handleSell('renewable')}
      >
        <Typography.Paragraph>Quantità da vendere:</Typography.Paragraph>
        <InputNumber
          min={1}
          max={Math.min(gameState.energyProduction.renewable, gameState.marketCapacity)}
          value={sellAmount}
          onChange={setSellAmount}
          style={{ width: '100%' }}
        />
      </Modal>

      {/* Modal per vendere energia fossile */}
      <Modal
        title="Vendi Energia Fossile"
        visible={isFossilModalVisible}
        onCancel={() => setIsFossilModalVisible(false)}
        onOk={() => handleSell('fossil')}
      >
        <Typography.Paragraph>Quantità da vendere:</Typography.Paragraph>
        <InputNumber
          min={1}
          max={Math.min(gameState.energyProduction.fossil, gameState.marketCapacity)}
          value={sellAmount}
          onChange={setSellAmount}
          style={{ width: '100%' }}
        />
      </Modal>
    </div>
  );
};

export default Market;
