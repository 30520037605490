import { Link } from 'react-router-dom';
import React from 'react';
import { Card, Progress } from 'antd'; // Importa il componente Progress di Ant Design
import styled from 'styled-components';

const { Meta } = Card;

const CourseCardContainer = styled(Card)`
  width: 100%;
  max-width: 300px;
  min-height: 200px; /* Imposta un'altezza minima per tutte le card */
  height: auto;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid #e7e5e4; /* Aggiungi il bordo con il colore specificato */

  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border: 1px solid #e7e5e4; /* Aggiungi il bordo con il colore specificato */

  }
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #1c1c1e;
  min-height: 48px;
  max-height: 48px;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Description = styled.div`
  font-size: 14px;
  color: #636366;
  margin-top: 8px;
  flex-grow: 1;
`;

const CourseButton = styled(Link)`
  display: block;
  margin-top: 12px;
  color: #007aff;
  text-transform: none;
  padding: 0;
  font-size: 14px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

function CourseCard({ title, participants, rating, link }) {
  return (
    <CourseCardContainer>
      <Meta 
        title={<Title>{title}</Title>}
        description={
          <Description>
            Partecipanti: {participants}
          </Description>
        }
      />
      <RatingContainer>
        <span>Valutazione:</span>
        <Progress type="circle" percent={rating} width={40} strokeColor="#34c759" />
      </RatingContainer>
      <CourseButton to={link}>
        Scopri di più
      </CourseButton>
    </CourseCardContainer>
  );
}

export default CourseCard;