import React, { useState } from 'react';
import { Card, Button, Modal, message } from 'antd';

const Inventory = ({ items, onUseItem }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleUseItemClick = () => {
    if (selectedItem && selectedItem.effect) {
      onUseItem(selectedItem); // Usa la funzione passata come prop
      setSelectedItem(null);
    } else {
      message.info('Questo oggetto non può essere utilizzato.');
    }
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
  };

  return (
    <div style={{ marginTop: '24px' }}>
      <Card title="Inventario">
        {items.length > 0 ? (
          items.map((item, index) => (
            <Button
              key={index}
              onClick={() => handleItemClick(item)}
              style={{ margin: '5px' }}
            >
              <span style={{ marginRight: '8px' }}>{item.icon}</span>
              {item.name}
            </Button>
          ))
        ) : (
          <p>Il tuo inventario è vuoto.</p>
        )}
      </Card>

      {selectedItem && (
        <Modal
          visible={true}
          title={selectedItem.name}
          onCancel={handleCloseModal}
          footer={[
            selectedItem.effect && (
              <Button key="use" type="primary" onClick={handleUseItemClick}>
                Usa
              </Button>
            ),
            <Button key="close" onClick={handleCloseModal}>
              Chiudi
            </Button>,
          ]}
        >
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '64px', marginBottom: '16px' }}>{selectedItem.icon}</div>
            <p>{selectedItem.description}</p>
            {selectedItem.content && <p>{selectedItem.content}</p>}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Inventory;
