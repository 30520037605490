// ESGMetropolis.js
import React, { useState } from 'react';
import { Layout, Typography, Tabs, message } from 'antd';
import Dashboard from './components/Dashboard';
import Facilities from './components/Facilities';
import ResearchAndDevelopment from './components/ResearchAndDevelopment';
import PersonnelManagement from './components/PersonnelManagement';
import Market from './components/Market';
import EventModal from './components/EventModal';
import MaterialityMatrix from './components/MaterialityMatrix'; // Importa il nuovo componente
import facilityTypes from './data/facilityTypes';
import energyEvents from './data/energyEvents';
// ESGMetropolis.js
import './styles/style.css';


const { Header, Content } = Layout;
const { Title } = Typography;
const { TabPane } = Tabs;

const ESGMetropolis = () => {
  const [gameState, setGameState] = useState({
    day: 1,
    money: 500000,
    energyProduction: {
      total: 0,
      renewable: 0,
      fossil: 0,
    },
    emissions: 50, // CO₂ emissions percentage
    reputation: 50,
    researchPoints: 0,
    events: [],
    facilities: [],
    personnel: [],
    marketDemand: 100, // Domanda iniziale (valore da 50 a 150 per simulare la variabilità)
    marketCapacity: 200,
    technologies: [
      { id: 1, name: "Pannelli Solari Avanzati", cost: 100, description: "Aumenta l'efficienza degli impianti solari del 20%.", progress: 0 },
      { id: 2, name: "Turbine Eoliche Avanzate", cost: 150, description: "Aumenta l'efficienza dei parchi eolici del 25%.", progress: 0 },
      // Aggiungi altre tecnologie...
    ],
    upgrades: [], // Nuovo stato per tenere traccia degli upgrade effettuati
  });
  

  const [currentEvent, setCurrentEvent] = useState(null);

  // Definisci la funzione calculatePrice qui, prima di usarla nel return
  const calculatePrice = (type) => {
    const basePrice = type === 'renewable' ? 70 : 40; // Prezzo base differente per tipo di energia
    const demandMultiplier = gameState.marketDemand / 100; // Moltiplicatore basato sulla domanda (tra 0.5 e 1.5)
    return Math.max(20, Math.floor(basePrice * demandMultiplier)); // Assicurati che il prezzo non scenda sotto 20
  };


  const calculateTotalCapacity = () => {
    return gameState.facilities.reduce((sum, facility) => {
      const maxCapacity = facility.maxCapacity || facilityTypes[facility.type].maxCapacity;
      return sum + maxCapacity;
    }, 0);
  };
  
  

  // Funzione per aggiornare lo stato aziendale ogni giorno
  const updateCompany = () => {
    // Aggiorna la domanda di mercato giornalmente (es. un valore casuale tra 50 e 150)
    const newMarketDemand = Math.floor(Math.random() * 101) + 50; // Genera un numero tra 50 e 150
    const newMarketCapacity = Math.floor(newMarketDemand * 2); // La capacità del mercato varia con la domanda
    const totalCapacity = calculateTotalCapacity();

    // Incrementa l'esperienza del personale
    const incrementedPersonnel = gameState.personnel.map(person => ({
      ...person,
      experience: person.experience + 10, // Incrementa di 10 punti ogni giorno
    }));
  
    // Funzione per far salire di livello il personale
    const levelUpPersonnel = (person) => {
      if (person.experience >= 100 * person.level) { // Ad esempio, 100 punti per livello
        return {
          ...person,
          level: person.level + 1,
          efficiencyBoost: (person.efficiencyBoost || 0) + 5, // Incremento bonus per livello
          researchBoost: (person.researchBoost || 0) + 2,
          reputationBoost: (person.reputationBoost || 0) + 1,
          experience: person.experience - 100 * person.level, // Resetta l'esperienza dopo il passaggio di livello
        };
      }
      return person;
    };
  
    // Applica il livello successivo ai membri del personale con esperienza sufficiente
    const updatedPersonnel = incrementedPersonnel.map(levelUpPersonnel);
  
    // Calcola i boost totali forniti dal personale aggiornato
    const efficiencyBoost = updatedPersonnel.reduce((sum, person) => sum + (person.efficiencyBoost || 0), 0);
    const researchBoost = updatedPersonnel.reduce((sum, person) => sum + (person.researchBoost || 0), 0);
    const reputationBoost = updatedPersonnel.reduce((sum, person) => sum + (person.reputationBoost || 0), 0);
  
    // Calcola la produzione energetica rinnovabile con il limite di capacità massima e il boost di efficienza
    
    // Calcola la produzione energetica rinnovabile con il limite di capacità massima e il boost di efficienza
    const totalRenewable = gameState.facilities
  .filter(facility => facilityTypes[facility.type].effect.energyProduction?.renewable)
  .reduce((sum, facility) => {
    const production = facilityTypes[facility.type].effect.energyProduction.renewable;
    const efficiency = facility.efficiency || facilityTypes[facility.type].efficiency;
    const maxCapacity = facility.maxCapacity || facilityTypes[facility.type].maxCapacity;
    console.log(`Produzione impianto solare, Efficienza: ${efficiency}, Capacità Massima: ${maxCapacity}`);
    return sum + Math.min(production * (efficiency / 100), maxCapacity);
  }, 0);


    // Calcola la produzione energetica fossile con il limite di capacità massima e il boost di efficienza
    const totalFossil = gameState.facilities
    .filter(facility => facilityTypes[facility.type].effect.energyProduction?.fossil)
    .reduce((sum, facility) => {
      const production = facilityTypes[facility.type].effect.energyProduction.fossil;
      const efficiency = facility.efficiency || facilityTypes[facility.type].efficiency;
      const maxCapacity = facility.maxCapacity || facilityTypes[facility.type].maxCapacity;
      console.log(`Produzione impianto fossile, Efficienza: ${efficiency}, Capacità Massima: ${maxCapacity}`);
      return sum + Math.min(production * (efficiency / 100), maxCapacity);
    }, 0);



  
    const totalProduction = totalRenewable + totalFossil;
  
    // Calcola le emissioni
    const emissions = Math.max(0, Math.min(100, gameState.emissions + (totalFossil / 100) - (totalRenewable / 200)));
  
    // Aggiorna il denaro (entrate dalle vendite di energia meno le spese operative)
    const revenue = totalProduction * 50; // Prezzo di vendita per unità energetica
    const expenses = gameState.facilities.length * 1000 + gameState.personnel.length * 2000;
    const money = gameState.money + revenue - expenses;
  
    // Aggiorna la reputazione, tenendo conto del boost e degli effetti della produzione energetica
    const reputation = Math.max(0, Math.min(100, gameState.reputation + (totalRenewable / 100) - (emissions / 100) + reputationBoost));
  
    // Aggiorna i punti di ricerca, includendo il boost del personale
    const researchPoints = gameState.researchPoints + gameState.facilities.filter(f => f.type === 'researchLab').length * 5 + researchBoost;
  
    // Aggiorna lo stato del gioco con i nuovi valori
    setGameState(prev => ({
      ...prev,
      day: prev.day + 1,
      energyProduction: {
        total: totalProduction,
        renewable: totalRenewable,
        fossil: totalFossil,
      },
      personnel: updatedPersonnel, // Aggiorna il personale con esperienza e livelli aggiornati
      emissions,
      money,
      reputation,
      researchPoints,
      marketDemand: newMarketDemand,
      marketCapacity: newMarketCapacity,
    }));
  
    // Ogni 7 giorni, attiva un evento casuale
    if ((gameState.day + 1) % 7 === 0) {
      triggerRandomEvent();
    }
  };
  

  // Funzione per aggiungere un impianto
  const addFacility = (type) => {
    if (gameState.money >= facilityTypes[type].cost) {
      setGameState(prev => ({
        ...prev,
        money: prev.money - facilityTypes[type].cost,
        facilities: [
          ...prev.facilities, 
          { 
            type, 
            efficiency: facilityTypes[type].efficiency || 100, // Imposta l'efficienza di base
            maxCapacity: facilityTypes[type].maxCapacity || 100, // Imposta la capacità massima di base
            level: 1 // Inizializza il livello a 1
          }
        ],
      }));
    }
  };
  

  // Funzione per migliorare un impianto
  // Funzione per migliorare un impianto
const upgradeFacility = (facilityIndex) => {
  setGameState(prev => {
    const facility = prev.facilities[facilityIndex];
    const facilityType = facilityTypes[facility.type];

    console.log('Upgrade Cost:', facilityType.cost / 2);
    console.log('Current Money:', prev.money);

    // Costo per migliorare l'impianto (ad esempio, metà del costo iniziale)
    const upgradeCost = facilityType.cost / 2;

    if (prev.money >= upgradeCost) {
      // Migliorare la capacità e l'efficienza dell'impianto
      const upgradedFacility = {
        ...facility,
        maxCapacity: (facility.maxCapacity || facilityType.maxCapacity) + 50, // Incremento di capacità
        efficiency: (facility.efficiency || facilityType.efficiency) + 10, // Incremento di efficienza del 10%
        level: (facility.level || 1) + 1, // Incremento del livello
      };

      console.log('Upgraded Facility:', upgradedFacility);

      // Aggiorna lo stato del gioco con l'impianto migliorato e riduci il denaro disponibile
      return {
        ...prev,
        money: prev.money - upgradeCost,
        facilities: [
          ...prev.facilities.slice(0, facilityIndex),
          upgradedFacility,
          ...prev.facilities.slice(facilityIndex + 1),
        ],
      };
    } else {
      // Messaggio di errore se non c'è abbastanza denaro
      message.error("Fondi insufficienti per migliorare l'impianto.");
      return prev;
    }
  });
};

  


  // Funzione per attivare un evento casuale
  const triggerRandomEvent = () => {
    setCurrentEvent(energyEvents[Math.floor(Math.random() * energyEvents.length)]);
  };

  // Funzione per gestire la scelta dell'evento
  const handleEventChoice = (effect) => {
    setGameState(prev => ({
      ...prev,
      money: prev.money + (effect.money || 0),
      emissions: Math.max(0, Math.min(100, prev.emissions + (effect.emissions || 0))),
      reputation: Math.max(0, Math.min(100, prev.reputation + (effect.reputation || 0))),
      researchPoints: prev.researchPoints + (effect.researchPoints || 0),
      energyProduction: {
        ...prev.energyProduction,
        renewable: prev.energyProduction.renewable + (effect.energyProduction?.renewable || 0),
        fossil: prev.energyProduction.fossil + (effect.energyProduction?.fossil || 0),
      },
      events: [...prev.events, { day: prev.day, ...currentEvent, choice: effect }],
    }));
    setCurrentEvent(null);
  };


  const applyTechnologyEffect = (tech) => {
    setGameState(prev => {
        let updatedFacilities = [...prev.facilities]; // Copia dello stato attuale delle facilities

        if (tech.name === "Pannelli Solari Avanzati") {
            updatedFacilities = updatedFacilities.map(facility => {
                if (facility.type === 'solarPlant') {
                    return {
                        ...facility,
                        efficiency: (facility.efficiency || facilityTypes[facility.type].efficiency) + 20, // Incrementa l'efficienza del 20%
                    };
                }
                return facility;
            });
        } else if (tech.name === "Turbine Eoliche Avanzate") {
            updatedFacilities = updatedFacilities.map(facility => {
                if (facility.type === 'windFarm') {
                    return {
                        ...facility,
                        efficiency: (facility.efficiency || facilityTypes[facility.type].efficiency) + 25, // Incrementa l'efficienza del 25%
                    };
                }
                return facility;
            });
        } else if (tech.name === "Centrale Idroelettrica Avanzata") {
            updatedFacilities = updatedFacilities.map(facility => {
                if (facility.type === 'hydroPlant') {
                    return {
                        ...facility,
                        efficiency: (facility.efficiency || facilityTypes[facility.type].efficiency) + 15, // Incrementa l'efficienza del 15%
                    };
                }
                return facility;
            });
        } else if (tech.name === "Miglioramento della Biomassa") {
            updatedFacilities = updatedFacilities.map(facility => {
                if (facility.type === 'biomassPlant') {
                    return {
                        ...facility,
                        efficiency: (facility.efficiency || facilityTypes[facility.type].efficiency) + 10, // Incrementa l'efficienza del 10%
                    };
                }
                return facility;
            });
        }

        return {
            ...prev,
            facilities: updatedFacilities, // Aggiorna la lista degli impianti
        };
    });
};

  
  
  // Funzione per investire in una tecnologia di R&S
  const investInTechnology = (tech) => {
    if (gameState.researchPoints >= tech.cost) {
      setGameState(prev => {
        // Aggiorna la lista delle tecnologie con il progresso
        const updatedTechnologies = prev.technologies.map(t => {
          if (t.id === tech.id) {
            const newProgress = t.progress + 20; // Incrementa il progresso di 20%
            if (newProgress >= 100) {
              // Se il progresso raggiunge o supera il 100%, applica l'effetto
              applyTechnologyEffect(t);
              return { ...t, progress: 100 }; // Tecnologia completata
            }
            return { ...t, progress: newProgress };
          }
          return t;
        });
  
        return {
          ...prev,
          researchPoints: prev.researchPoints - tech.cost, // Deduce il costo in punti ricerca
          technologies: updatedTechnologies, // Aggiorna la lista delle tecnologie
        };
      });
      message.success(`Hai investito in ${tech.name}`);
    } else {
      message.error("Punti ricerca insufficienti.");
    }
  };
  
  
  

  // Funzione per assumere personale
  const hirePersonnel = (person) => {
    if (gameState.money >= person.salary) {
      setGameState(prev => ({
        ...prev,
        money: prev.money - person.salary,
        personnel: [...prev.personnel, person],
      }));
    }
  };
  

  // Funzione per vendere energia
  const sellEnergy = (amount, price, type) => {
    let reputationChange = 0;
    let emissionsChange = 0;
  
    // Determina l'impatto in base al tipo di energia venduta
    if (type === 'renewable') {
      reputationChange = 2; // Aumenta la reputazione di 2 per ogni unità venduta di energia rinnovabile
    } else if (type === 'fossil') {
      reputationChange = -1; // Riduce la reputazione di 1 per ogni unità venduta di energia fossile
      emissionsChange = 1; // Aumenta le emissioni di 1 per ogni unità venduta di energia fossile
    }
  
    // Aggiorna lo stato del gioco
    setGameState(prev => ({
      ...prev,
      money: prev.money + (amount * price),
      energyProduction: {
        ...prev.energyProduction,
        [type]: prev.energyProduction[type] - amount, // Riduce la quantità di energia venduta dal tipo specifico
      },
      reputation: Math.max(0, Math.min(100, prev.reputation + reputationChange)),
      emissions: Math.max(0, Math.min(100, prev.emissions + emissionsChange)),
    }));
  };
  

  return (
    <Layout>
      <Header>
        <Title level={3} style={{ color: 'white', margin: 0 }}>ESG Metropolis</Title>
      </Header>
      <Content style={{ padding: '24px', maxWidth: '1200px', margin: '0 auto' }}>
        <Tabs defaultActiveKey="dashboard">
          <TabPane tab="Dashboard" key="dashboard">
            <Dashboard gameState={gameState} updateCompany={updateCompany} totalCapacity={calculateTotalCapacity()} />
          </TabPane>
          <TabPane tab="Impianti" key="facilities">
            <Facilities 
              gameState={gameState} 
              facilityTypes={facilityTypes} 
              addFacility={addFacility} 
              upgradeFacility={upgradeFacility}
            />
          </TabPane>
          <TabPane tab="Ricerca e Sviluppo" key="research">
            <ResearchAndDevelopment gameState={gameState} investInTechnology={investInTechnology} />
          </TabPane>
          <TabPane tab="Personale" key="personnel">
            <PersonnelManagement gameState={gameState} hirePersonnel={hirePersonnel} />
          </TabPane>
          <TabPane tab="Mercato" key="market">
            <Market gameState={gameState} sellEnergy={sellEnergy} calculatePrice={calculatePrice} />
          </TabPane>
          <TabPane tab="Matrice di Materialità" key="materialityMatrix">
            <MaterialityMatrix gameState={gameState} />
          </TabPane>
        </Tabs>

        {currentEvent && (
          <EventModal
            currentEvent={currentEvent}
            handleEventChoice={handleEventChoice}
          />
        )}
      </Content>
    </Layout>
  );
};


export default ESGMetropolis;
