import React, { useState, useEffect } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import StatisticCard from '../components/StatisticCard';
import CourseCard from '../components/CourseCard';
import PerformanceList from '../components/PerformanceList';
import UtilizationChart from '../components/UtilizationChart';
import ImprovementChart from '../components/ImprovementChart';
import { FaDollarSign, FaUser, FaClipboardCheck, FaChartLine } from 'react-icons/fa';
import styled from 'styled-components';
import apiUrl from '../config';
import '../styles/Dashboard.css';
import '../styles/theme.css';

// Container principale della dashboard
const DashboardContainer = styled(Container)`
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

// Container per il layout della griglia
const GridContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 0 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 10px;
  }
`;

// Stile per le colonne della dashboard
const LeftColumn = styled(Grid)`
  flex-grow: 2;
`;

const RightColumn = styled(Grid)`
  flex-grow: 1;
  margin-left: 20px;
`;

// Contenitore per lo scrolling orizzontale delle card
const HorizontalScrollContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  overflow-x: auto;
  padding-bottom: 10px;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  & > * {
    flex: 0 1 calc(33.3333% - 16px);
    margin-right: 16px;
  }

  @media (max-width: 768px) {
    gap: 8px;
  }
`;

// Stile delle card personalizzate
const CustomCard = styled.div`
  background-color: white;
  width: 300px;  /* Larghezza fissa per le card */
  height: 264px;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #e7e5e4;  /* Aggiunge un bordo sottile */

  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }

  .chart-container {
    width: 100%;
    height: 200px;
  }
`;

function Dashboard() {
  const [assessments, setAssessments] = useState([]);
  const [performanceData, setPerformanceData] = useState({});

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/get-assessments`);
        if (response.ok) {
          const data = await response.json();
          setAssessments(data);

          const updatedPerformanceData = data.reduce((acc, assessment) => {
            const participants = Math.floor(Math.random() * 48) + 50;
            const rating = Math.floor(Math.random() * 48) + 50;
            const averageImprovement = Math.round(Math.random() * 20) + 10;
            acc[assessment.id] = { participants, rating, averageImprovement };
            return acc;
          }, {});
          setPerformanceData(updatedPerformanceData);
        }
      } catch (error) {
        console.error('Errore durante la chiamata al backend:', error);
      }
    };

    fetchAssessments();
  }, []);

  const bestPerformanceData = assessments
    .filter(assessment => performanceData[assessment.id]?.rating > 50)
    .sort((a, b) => performanceData[b.id]?.rating - performanceData[a.id]?.rating)
    .slice(0, 3)
    .map(assessment => ({
      id: assessment.id,
      course: assessment.courseName,
      ...performanceData[assessment.id],
    }));

  const worstPerformanceData = assessments
    .filter(assessment => performanceData[assessment.id]?.rating <= 50)
    .sort((a, b) => performanceData[a.id]?.rating - performanceData[b.id]?.rating)
    .slice(0, 3)
    .map(assessment => ({
      id: assessment.id,
      course: assessment.courseName,
      ...performanceData[assessment.id],
    }));

  return (
    <DashboardContainer>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <div className="dashboard-card">
            <FaDollarSign className="icon" />
            <div className="number">$45,231.89</div>
            <div className="label">Ritorno Investimento</div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className="dashboard-card">
            <FaUser className="icon" />
            <div className="number">+2350</div>
            <div className="label">Studenti</div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className="dashboard-card">
            <FaClipboardCheck className="icon" />
            <div className="number">+12,234</div>
            <div className="label">Assessment Completati</div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className="dashboard-card">
            <FaChartLine className="icon" />
            <div className="number">+573</div>
            <div className="label">Attivi Ora</div>
          </div>
        </Grid>
      </Grid>

      <GridContainer container spacing={3}>
        <LeftColumn item xs={12} md={8}>
        <Typography variant="h5" gutterBottom style={{ marginBottom: '20px', marginTop: '40px' }}>
  I miei corsi
</Typography>
          <HorizontalScrollContainer>
            {assessments.map((assessment) => (
              <Grid item xs={12} sm={6} md={4} key={assessment.id}>
                <CourseCard
                  title={assessment.courseName}
                  participants={performanceData[assessment.id]?.participants || 0}
                  rating={performanceData[assessment.id]?.rating || 0}
                  link={`/Assessment/${assessment.id}`}
                />
              </Grid>
            ))}
          </HorizontalScrollContainer>

          <Grid container spacing={3} style={{ marginTop: '20px' }}>
            <Grid item xs={12} md={6}>
              <CustomCard>
                <Typography variant="h6">Tempo di utilizzo</Typography>
                <div className="chart-container">
                  <UtilizationChart />
                </div>
              </CustomCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomCard>
                <Typography variant="h6">Miglioramento medio degli studenti</Typography>
                <div className="chart-container">
                  <ImprovementChart value={Object.values(performanceData).reduce((sum, data) => sum + data.averageImprovement, 0) / Object.values(performanceData).length || 0} />
                </div>
              </CustomCard>
            </Grid>
          </Grid>
        </LeftColumn>

        <RightColumn item xs={12} md={4} style={{ marginTop: '30px', marginBottom: '20px' }}>
          <PerformanceList title="Best performance" data={bestPerformanceData} good />
          <PerformanceList title="Worst performance" data={worstPerformanceData} />
        </RightColumn>

      </GridContainer>
    </DashboardContainer>
  );
}

export default Dashboard;
