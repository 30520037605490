// src/components/gamification/LeadershipSimulator/data/skills.js

const skills = [
    {
      id: 1,
      name: 'Gestione del Tempo',
      icon: '⏰',
      description: 'Capacità di organizzare e gestire efficacemente il proprio tempo e le risorse.',
    },
    {
      id: 2,
      name: 'Comunicazione Efficace',
      icon: '🗣️',
      description: 'Abilità di trasmettere informazioni chiaramente e ascoltare attivamente.',
    },
    // Aggiungi altre abilità qui
  ];
  
  export default skills;
  