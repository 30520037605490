const emails = [
  {
    id: 1,
    sender: 'Direttore HR',
    senderRole: 'Direttore',
    subject: 'Richiesta di Feedback sul Team',
    content: `Caro/a [Nome],

Vorremmo conoscere il tuo feedback sulla performance del tuo team negli ultimi mesi. Per favore, fornisci le tue osservazioni.

Grazie,
Direzione HR`,
    trigger: 'mission_completed',
    type: 'text', // Tipo di risposta: testuale
    isRead: false,
    userResponse: null,
    effects: {
      positive: { Comunicazione: 10, Empatia: 5 },
      neutral: { Comunicazione: 5 },
      negative: { Comunicazione: -10, Empatia: -5 },
    },
  },
  {
    id: 2,
    sender: 'Manager Operativo',
    senderRole: 'Manager',
    subject: 'Aggiornamento del Progetto',
    content: `Caro/a [Nome],

Desideriamo un aggiornamento sullo stato attuale del progetto XYZ. Ti preghiamo di fornirci un resoconto dettagliato entro la fine della settimana.

Cordiali saluti,
Manager Operativo`,
    trigger: 'level_up',
    type: 'options', // Tipo di risposta: multiple choice
    options: [
      {
        text: 'Fornire un aggiornamento dettagliato.',
        effect: { Comunicazione: 15 },
        xpGained: 50,
        reputationChange: { 2: 7 }, // Modificato per usare l'id del personaggio
        nextScenarioId: 36,
      },
      {
        text: 'Fornire un aggiornamento breve.',
        effect: { Comunicazione: 5 },
        xpGained: 20,
        reputationChange: { 2: 3 }, // Modificato per usare l'id del personaggio
        nextScenarioId: 37,
      },
      {
        text: 'Non fornire alcun aggiornamento.',
        effect: { Comunicazione: -15 },
        xpGained: -10,
        reputationChange: { 2: -7 }, // Modificato per usare l'id del personaggio
        nextScenarioId: 38,
      },
    ],
    isRead: false,
    userResponse: null,
  },
  {
    id: 3,
    sender: 'CEO',
    senderRole: 'CEO',
    subject: 'Benvenuto a Bordo!',
    content: `Caro/a [Nome],

Benvenuto nella nostra azienda! Siamo entusiasti di averti nel nostro team e non vediamo l'ora di vedere il tuo contributo.

Cordiali saluti,
CEO`,
    trigger: 'game_start',
    type: 'options',
    options: [
      {
        text: 'Ringraziare calorosamente.',
        effect: { Comunicazione: 5, Empatia: 5 },
        reputationChange: { 6: 10 }, // Modificato per usare l'id del CEO
        nextScenarioId: 39,
      },
      {
        text: 'Rispondere formalmente.',
        effect: { Comunicazione: 3 },
        reputationChange: { 6: 5 }, // Modificato per usare l'id del CEO
        nextScenarioId: 40,
      },
    ],
    isRead: false,
    userResponse: null,
  },
  {
    id: 4,
    sender: 'Direttore Marketing',
    senderRole: 'Direttore',
    subject: 'Suggerimenti per la Campagna Pubblicitaria',
    content: `Caro/a [Nome],

Abbiamo bisogno dei tuoi suggerimenti per migliorare la nostra prossima campagna pubblicitaria. Per favore, condividi le tue idee.

Grazie,
Direzione Marketing`,
    trigger: 'marketing_suggestion',
    type: 'text',
    isRead: false,
    userResponse: null,
    effects: {
      positive: { Comunicazione: 10, Creatività: 15 },
      neutral: { Comunicazione: 5 },
      negative: { Comunicazione: -10 },
    },
  },
  {
    id: 5,
    sender: 'Team Leader IT',
    senderRole: 'Team Leader',
    subject: 'Implementazione del Nuovo Sistema',
    content: `Caro/a [Nome],

Stiamo per implementare un nuovo sistema gestionale. Vorremmo conoscere la tua opinione sulla formazione necessaria per il tuo team.

Cordiali saluti,
Team Leader IT`,
    trigger: 'system_implementation',
    type: 'options',
    options: [
      {
        text: 'Richiedere una formazione completa.',
        effect: { Comunicazione: 10, Leadership: 5 },
        xpGained: 30,
        reputationChange: { 4: 5 }, // Modificato per usare l'id del Team Leader IT
        nextScenarioId: 41,
      },
      {
        text: 'Richiedere una formazione minima.',
        effect: { Comunicazione: 5 },
        xpGained: 15,
        reputationChange: { 4: 3 }, // Modificato per usare l'id del Team Leader IT
        nextScenarioId: 42,
      },
      {
        text: 'Non richiedere alcuna formazione.',
        effect: { Comunicazione: -10 },
        xpGained: -5,
        reputationChange: { 4: -5 }, // Modificato per usare l'id del Team Leader IT
        nextScenarioId: 43,
      },
    ],
    isRead: false,
    userResponse: null,
  },
  {
    id: 6,
    sender: 'Responsabile Sicurezza',
    senderRole: 'Responsabile',
    subject: 'Aggiornamento sulle Normative di Sicurezza',
    content: `Caro/a [Nome],

Ti informiamo che ci sono nuove normative di sicurezza da implementare nel tuo dipartimento. Per favore, conferma di averle ricevute e se hai bisogno di supporto.

Grazie,
Responsabile Sicurezza`,
    trigger: 'security_update',
    type: 'options',
    options: [
      {
        text: 'Confermare la ricezione e richiedere supporto.',
        effect: { Comunicazione: 8, Leadership: 4 },
        xpGained: 25,
        reputationChange: { 5: 6 }, // Modificato per usare l'id del Responsabile Sicurezza
        nextScenarioId: 44,
      },
      {
        text: 'Confermare la ricezione senza richiedere supporto.',
        effect: { Comunicazione: 4 },
        xpGained: 10,
        reputationChange: { 5: 3 }, // Modificato per usare l'id del Responsabile Sicurezza
        nextScenarioId: 45,
      },
      {
        text: 'Ignorare la comunicazione.',
        effect: { Comunicazione: -12 },
        xpGained: -15,
        reputationChange: { 5: -8 }, // Modificato per usare l'id del Responsabile Sicurezza
        nextScenarioId: 46,
      },
    ],
    isRead: false,
    userResponse: null,
  },
  // Puoi aggiungere ulteriori email seguendo questo schema
];

export default emails;
