import React, { useState } from 'react';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const LayoutContainer = styled.div`
  display: flex;
  min-height: 100vh;
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding: 20px;
  margin-left: ${({ isSidebarOpen }) => (isSidebarOpen ? '250px' : '80px')};  // Cambia in base allo stato della sidebar
  transition: margin-left 0.3s ease;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

function Layout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);  // Stato per sidebar aperta o chiusa

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);  // Inverti lo stato della sidebar
  };

  return (
    <LayoutContainer>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />  {/* Passa lo stato e la funzione */}
      <MainContent isSidebarOpen={isSidebarOpen}>
        <Outlet />
      </MainContent>
    </LayoutContainer>
  );
}

export default Layout;
