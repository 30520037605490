// src/components/Relationships.js

import React from 'react';
import { List, Progress, Typography } from 'antd';
import relationshipsData from '../data/relationships';

const { Text } = Typography;

const Relationships = ({ relationships, onClose }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={relationshipsData}
      renderItem={rel => (
        <List.Item>
          <List.Item.Meta
            title={
              <Text>
                {rel.icon} {rel.name}
              </Text>
            }
            description={
              <Progress
                percent={relationships[rel.id]}
                format={percent => `${percent}/100`}
                strokeColor={{
                  '0%': '#f5222d',
                  '100%': '#52c41a',
                }}
              />
            }
          />
        </List.Item>
      )}
    />
  );
};

export default Relationships;
