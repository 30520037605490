const items = [
    {
      id: 'gratitude_letter',
      name: 'Lettera di Gratitudine',
      description: 'Una lettera scritta da un membro del tuo team per ringraziarti del tuo supporto.',
      content: `Caro/a [${'{'}playerName{'}'}],
  
  Volevo esprimerti la mia sincera gratitudine per il tuo aiuto durante questo periodo difficile. La tua comprensione e il tuo supporto hanno fatto la differenza.
  
  Grazie di cuore,
  [Collega]`,
      type: 'letter',
    },
    {
      id: 'efficiency_medal',
      name: 'Medaglia di Efficienza',
      description: 'Un riconoscimento per aver gestito efficacemente un progetto cruciale.',
      type: 'medal',
    },
    {
      id: 'performance_report',
      name: 'Rapporto di Valutazione',
      description: 'Un rapporto dettagliato sulle prestazioni recenti del team.',
      content: `Il team ha mostrato un notevole miglioramento nelle seguenti aree:
  - Comunicazione
  - Collaborazione
  - Raggiungimento degli obiettivi
  
  Continua così!`,
      type: 'report',
    },
    {
      id: 'leadership_manual',
      name: 'Manuale di Leadership',
      description: 'Un libro che fornisce preziosi consigli per migliorare le tue capacità di leadership.',
      content: `**Capitolo 1: Comunicazione Efficace**
  
  La chiave per una leadership di successo è la capacità di comunicare chiaramente...
  
  **Capitolo 2: Gestione dei Conflitti**
  
  Affrontare i conflitti in modo costruttivo può rafforzare il team...`,
      type: 'book',
      effect: {
        Leadership: 5,
        'Problem Solving': 5,
      },
    },
    {
      id: 'organizer_agenda',
      name: 'Agenda Organizzativa',
      description: 'Uno strumento per aiutarti a organizzare meglio il tuo tempo e le attività del team.',
      type: 'tool',
      effect: {
        'Gestione del Tempo': 10,
      },
    },
    {
      id: 'conference_ticket',
      name: 'Biglietto per Conferenza',
      description: 'Un biglietto per partecipare a una conferenza di settore, dove puoi imparare nuove competenze.',
      type: 'ticket',
      unlocksScenarioId: 15, // Questo scenario dovrà essere creato
    },
    {
      id: 'team_photo',
      name: 'Foto del Team',
      description: 'Una foto ricordo del team al completo, rafforzando il senso di appartenenza.',
      type: 'photo',
      effect: {
        Empatia: 5,
      },
    },
    {
        id: 'leadership_course_certificate',
        name: 'Certificato Corso di Leadership',
        description: 'Un certificato che attesta il completamento di un corso avanzato di leadership.',
        type: 'certificate',
        effect: {
          Leadership: 10,
        },
        icon: '📜',
      },
      {
        id: 'innovation_award',
        name: 'Premio Innovazione',
        description: 'Un riconoscimento per il progetto innovativo realizzato dal tuo team.',
        type: 'award',
        icon: '🏆',
      },
      {
        id: 'time_management_guide',
        name: 'Guida alla Gestione del Tempo',
        description: 'Una guida pratica per migliorare le tue capacità di gestione del tempo.',
        type: 'book',
        effect: {
          'Gestione del Tempo': 10,
        },
        icon: '📘',
      },
      {
        id: 'mentor_badge',
        name: 'Badge di Mentore',
        description: 'Un riconoscimento per il tuo ruolo di mentore all\'interno dell\'azienda.',
        type: 'badge',
        icon: '🎖️',
      },
  ];
  
  export default items;
  