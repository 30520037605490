// src/components/LeadershipSimulator.js

import React, { useState, useEffect } from 'react';
import {
  Layout, Typography, Card, Button, Progress,
  Space, Modal, Input, Avatar, Select, message, List, Divider, Badge
} from 'antd';
import { initialScenarios } from '../data/scenarios';
import { avatars } from '../data/avatars';
import { competencies } from '../data/competencies';
import items from '../data/items';
import relationships from '../data/relationships.js';
import skills from '../data/skills';
import missions from '../data/missions';
import emails from '../data/emails';
import Inventory from './inventory';
import Emails from './Emails.js';
import '../styles/LeadershipSimulator.css';
import Relationships from './Relationships';

const { Header, Content } = Layout;
const { Title, Paragraph, Text } = Typography;
const { Option } = Select;

// Definisci initialState fuori dal componente
const initialState = {
  currentScenarioId: initialScenarios[0].id,
  level: 1,
  xp: 0,
  xpToNextLevel: 100,
  competencies: competencies.reduce((acc, comp) => ({ ...acc, [comp.name]: 50 }), {}),
  decisions: [],
  character: null,
  inventory: [],
  emails: [],
  relationships: relationships.reduce((acc, rel) => ({ ...acc, [rel.id]: rel.reputation }), {}),
  skills: skills.reduce((acc, skill) => ({ ...acc, [skill.id]: 0 }), {}),
  missions: missions,
  unlockedAchievements: [],
  decisionHistory: [],
  emailResponses: [],
  timeManagement: {
    totalTime: 0,
    scenarioTimes: {},
    simulationStartTime: null,
    scenarioStartTime: null,
  },
};


// Funzione per salvare lo stato nel localStorage
const saveGameState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('leadershipRPGGameState', serializedState);
  } catch (e) {
    console.error('Errore nel salvataggio dello stato del gioco:', e);
  }
};

// Funzione per caricare lo stato dal localStorage
const loadGameState = () => {
  try {
    const serializedState = localStorage.getItem('leadershipRPGGameState');
    if (serializedState === null) {
      return undefined; // Usa lo stato iniziale se non c'è nulla in localStorage
    }
    return JSON.parse(serializedState);
  } catch (e) {
    console.error('Errore nel caricamento dello stato del gioco:', e);
    return undefined;
  }
};

const LeadershipSimulator = () => {
  // Inizializza lo stato con loadGameState() o initialState
  const [gameState, setGameState] = useState(() => loadGameState() || initialState);

  // useEffect per salvare lo stato ogni volta che gameState cambia
  useEffect(() => {
    saveGameState(gameState);
  }, [gameState]);

  const [reportData, setReportData] = useState(null);
  const [relationshipsVisible, setRelationshipsVisible] = useState(false);
  const [showCharacterCreation, setShowCharacterCreation] = useState(true);
  const [showSummary, setShowSummary] = useState(false);
  const [playerName, setPlayerName] = useState('');
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [unlockedItem, setUnlockedItem] = useState(null);
  const [inventoryVisible, setInventoryVisible] = useState(false);

  const formatEffect = (effect) => {
    return Object.entries(effect).map(([competency, value]) => (
      <span key={competency}>
        {competency}: {value > 0 ? `+${value}` : value};&nbsp;
      </span>
    ));
  };

  const handleRelationshipsOpen = () => {
    setRelationshipsVisible(true);
  };

  const handleRelationshipsClose = () => {
    setRelationshipsVisible(false);
  };

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    let timeString = '';
    if (hrs > 0) {
      timeString += `${hrs}h `;
    }
    if (mins > 0 || hrs > 0) {
      timeString += `${mins}m `;
    }
    timeString += `${secs}s`;

    return timeString.trim();
  };

  useEffect(() => {
    // Imposta l'ora di inizio della simulazione
    setGameState(prev => ({
      ...prev,
      timeManagement: {
        ...prev.timeManagement,
        simulationStartTime: new Date(),
        scenarioStartTime: new Date(),
      },
    }));
  }, []);

  useEffect(() => {
    if (showSummary) {
      generateReport();
    }
  }, [showSummary]);

  const generateReport = () => {
    const competenciesScores = gameState.competencies;

    // Identificare punti di forza e aree di miglioramento
    const strengths = [];
    const improvements = [];

    Object.entries(competenciesScores).forEach(([competency, score]) => {
      if (score >= 70) {
        strengths.push({ competency, score });
      } else if (score <= 50) {
        improvements.push({ competency, score });
      }
    });

    // Preparare il contenuto del report
    const reportData = {
      playerName: gameState.character.name,
      level: gameState.level,
      totalTime: gameState.timeManagement.totalTime,
      competenciesScores,
      strengths,
      improvements,
      decisionHistory: gameState.decisionHistory,
      emailResponses: gameState.emailResponses,
    };

    // Salva il reportData nello stato o gestiscilo come preferisci
    setReportData(reportData);
  };

  // Funzione per ricevere email basate su eventi
  const receiveEmail = (eventType) => {
    let emailToSend = emails.find(email => email.trigger === eventType);
    console.log(`Cercando email con trigger: ${eventType}`);
    console.log('Email trovata:', emailToSend);

    if (emailToSend) {
      setGameState(prev => {
        const emailAlreadyExists = prev.emails.some(email => email.id === emailToSend.id);
        console.log('Email già presente:', emailAlreadyExists);

        if (!emailAlreadyExists) {
          const emailWithUserResponse = {
            ...emailToSend,
            userResponse: null,
            receivedAt: new Date(),
            senderRole: emailToSend.senderRole || 'Collega',
            penaltyApplied: false,
            isRead: false, // Aggiungi proprietà isRead
          };

          const updatedEmails = [...prev.emails, emailWithUserResponse];
          console.log('Emails aggiornate:', updatedEmails);

          // Mostra la notifica solo se l'email è stata aggiunta
          message.info(`Hai ricevuto un nuovo messaggio da ${emailToSend.sender}!`);

          return {
            ...prev,
            emails: updatedEmails,
          };
        } else {
          console.log('Email già esistente nello stato, non viene aggiunta.');
          return prev;
        }
      });
    } else {
      console.log(`Nessuna email trovata per il trigger: ${eventType}`);
    }
  };

  // Funzione per controllare email non risposte periodicamente
  useEffect(() => {
    const interval = setInterval(() => {
      checkUnansweredEmails();
    }, 60000); // Controlla ogni 60 secondi

    return () => clearInterval(interval); // Pulizia all'unmount
  }, []);

  const checkUnansweredEmails = () => {
    setGameState(prev => {
      const currentTime = new Date();
      const newEmails = [...prev.emails];
      let newCompetencies = { ...prev.competencies };

      // Definisci il limite di tempo (es. 5 minuti)
      const timeLimit = 5 * 60 * 1000; // 5 minuti in millisecondi

      // Itera sulle email
      newEmails.forEach(email => {
        const timeSinceReceived = currentTime - new Date(email.receivedAt);

        if (timeSinceReceived > timeLimit && !email.penaltyApplied) {
          // Applica la penalità in base all'importanza del mittente
          let penalty = 0;
          if (email.senderRole === 'CEO' || email.senderRole === 'Cliente Importante') {
            penalty = -10;
            newCompetencies['Gestione del Tempo'] = Math.max(0, newCompetencies['Gestione del Tempo'] - 5);
          } else if (email.senderRole === 'Collega') {
            penalty = -5;
          }

          // Aggiorna le competenze
          newCompetencies['Comunicazione'] = Math.max(0, newCompetencies['Comunicazione'] + penalty);

          // Marca la penalità come applicata
          email.penaltyApplied = true;

          // Opzionalmente, invia una notifica all'utente
          message.warning(`Non hai risposto a un'email importante da ${email.sender}. Hai perso punti in Comunicazione.`);
        }
      });

      return {
        ...prev,
        emails: newEmails,
        competencies: newCompetencies,
      };
    });
  };

  // Funzione per gestire la scelta dello scenario
  const handleScenarioChoice = (option) => {
    setGameState(prev => {
      // Calcola le nuove competenze
      const newCompetencies = { ...prev.competencies };
      Object.keys(option.effect || {}).forEach(key => {
        newCompetencies[key] = Math.max(0, Math.min(100, prev.competencies[key] + option.effect[key]));
      });

      // Aggiorna le relazioni
      let newRelationships = { ...prev.relationships };
      if (option.reputationChange) {
        Object.keys(option.reputationChange).forEach(relId => {
          newRelationships[relId] = Math.max(0, Math.min(100, (newRelationships[relId] || 0) + option.reputationChange[relId]));
        });
      }

      // Calcola XP e Livello
      let newXp = prev.xp + (option.xpGained || 0);
      let newLevel = prev.level;
      let newXpToNextLevel = prev.xpToNextLevel;
      let leveledUp = false;

      if (newXp >= newXpToNextLevel) {
        newLevel += 1;
        newXp -= newXpToNextLevel;
        newXpToNextLevel = Math.floor(newXpToNextLevel * 1.5);
        leveledUp = true;
        message.success(`Congratulazioni! Sei salito al livello ${newLevel}!`);
      }

      // Aggiorna l'inventario
      let newInventory = prev.inventory;
      if (option.itemId) {
        const newItem = items.find(item => item.id === option.itemId);
        if (newItem && !prev.inventory.some(item => item.id === newItem.id)) {
          newInventory = [...prev.inventory, newItem];
          setUnlockedItem(newItem);
          message.success(`Hai ottenuto: ${newItem.name}!`);
        }
      }

      // Registra la decisione
      const currentScenario = initialScenarios.find(s => s.id === prev.currentScenarioId);
      const decisionDetail = {
        scenarioId: currentScenario.id,
        scenarioTitle: currentScenario.title,
        choiceText: option.text,
        effect: option.effect,
        timestamp: new Date(),
      };
      const newDecisionHistory = [...prev.decisionHistory, decisionDetail];

      // Calcola il tempo
      const scenarioEndTime = new Date();
      const scenarioStartTime = prev.timeManagement.scenarioStartTime || scenarioEndTime;
      const timeSpent = (scenarioEndTime - new Date(scenarioStartTime)) / 1000;
      const newScenarioTimes = { ...prev.timeManagement.scenarioTimes };
      newScenarioTimes[prev.currentScenarioId] = timeSpent;
      const newTotalTime = prev.timeManagement.totalTime + timeSpent;

      // Trova il prossimo scenario
      const nextScenarioId = option.nextScenarioId;
      const nextScenarioIndex = initialScenarios.findIndex(s => s.id === nextScenarioId);
      if (nextScenarioIndex === -1 || nextScenarioId === null) {
        setShowSummary(true);
      }

      // Prepara il nuovo stato
      const newState = {
        ...prev,
        currentScenarioId: nextScenarioId,
        competencies: newCompetencies,
        relationships: newRelationships,
        decisions: [...prev.decisions, { scenario: currentScenario, choice: option }],
        decisionHistory: newDecisionHistory,
        xp: newXp,
        level: newLevel,
        xpToNextLevel: newXpToNextLevel,
        inventory: newInventory,
        timeManagement: {
          ...prev.timeManagement,
          totalTime: newTotalTime,
          scenarioTimes: newScenarioTimes,
          scenarioStartTime: new Date(),
        },
      };

      // Log dello stato aggiornato
      console.log('Stato Aggiornato:', newState);

      // Trigger per eventi basati sulla scelta
      if (leveledUp) {
        receiveEmail('level_up');
      }
      if (option.itemId) {
        receiveEmail('mission_completed');
      }

      return newState;
    });
  };

  // Funzione per usare un oggetto dall'inventario
  const handleUseItem = (item) => {
    if (item.effect) {
      const newCompetencies = { ...gameState.competencies };
      for (const [key, value] of Object.entries(item.effect)) {
        newCompetencies[key] = Math.min(100, newCompetencies[key] + value);
      }
      setGameState(prev => ({
        ...prev,
        competencies: newCompetencies,
        inventory: prev.inventory.filter(i => i.id !== item.id),
      }));
      message.success(`Hai usato ${item.name} e migliorato le tue competenze!`);
    } else {
      message.info('Questo oggetto non può essere utilizzato.');
    }
  };

  // Funzione per iniziare il gioco
  const startGame = () => {
    if (!playerName || !selectedAvatar) {
      message.error('Per favore, inserisci il tuo nome e seleziona un avatar.');
      return;
    }
    setGameState(prev => ({
      ...prev,
      character: {
        name: playerName,
        avatar: selectedAvatar,
      },
    }));
    setShowCharacterCreation(false);
    receiveEmail('game_start'); // Eventuale email al inizio del gioco
  };

  // Funzione per riavviare il gioco
  const restartGame = () => {
    // Resetta lo stato del gioco
    setGameState(initialState);
    
    // Resetta gli stati locali
    setShowCharacterCreation(true);
    setShowSummary(false);
    setPlayerName('');
    setSelectedAvatar(null);
    setUnlockedItem(null);
    setInventoryVisible(false);
    setRelationshipsVisible(false);
    
    // Pulisce il localStorage
    localStorage.removeItem('leadershipRPGGameState');
    
    // Notifica all'utente
    message.success('Il gioco è stato resettato.');
  };
  

  // Funzione per gestire la risposta alle email
  // Funzione per gestire la risposta alle email
const handleRespondEmail = (emailId, selectedOption) => {
  setGameState(prev => {
    // Trova l'indice dell'email
    const emailIndex = prev.emails.findIndex(e => e.id === emailId);
    if (emailIndex === -1) return prev;

    // Crea una copia dell'email e aggiorna con la risposta
    const updatedEmail = {
      ...prev.emails[emailIndex],
      userResponse: selectedOption.text,
      respondedAt: new Date(),
      isRead: true, // Segna come letto
    };

    // Aggiorna l'array delle email
    const updatedEmails = [...prev.emails];
    updatedEmails[emailIndex] = updatedEmail;

    // Registra la risposta all'email
    const emailResponse = {
      emailId: updatedEmail.id,
      subject: updatedEmail.subject,
      sender: updatedEmail.sender,
      responseText: selectedOption.text,
      timestamp: new Date(),
    };

    const newEmailResponses = [...prev.emailResponses, emailResponse];

    // Aggiorna le competenze
    const newCompetencies = { ...prev.competencies };
    Object.keys(selectedOption.effect || {}).forEach(key => {
      newCompetencies[key] = Math.max(0, Math.min(100, prev.competencies[key] + selectedOption.effect[key]));
    });

    // Aggiorna le relazioni
    let newRelationships = { ...prev.relationships };
    if (selectedOption.reputationChange) {
      Object.keys(selectedOption.reputationChange).forEach(relId => {
        newRelationships[relId] = Math.max(0, Math.min(100, (newRelationships[relId] || 0) + selectedOption.reputationChange[relId]));
      });
    }

    // Calcola XP e Livello
    let newXp = prev.xp + (selectedOption.xpGained || 0);
    let newLevel = prev.level;
    let newXpToNextLevel = prev.xpToNextLevel;
    let leveledUp = false;

    if (newXp >= newXpToNextLevel) {
      newLevel += 1;
      newXp -= newXpToNextLevel;
      newXpToNextLevel = Math.floor(newXpToNextLevel * 1.5);
      leveledUp = true;
      message.success(`Congratulazioni! Sei salito al livello ${newLevel}!`);
    }

    // Aggiorna l'inventario se c'è un nuovo oggetto
    let newInventory = prev.inventory;
    if (selectedOption.itemId) {
      const newItem = items.find(item => item.id === selectedOption.itemId);
      if (newItem && !prev.inventory.some(item => item.id === newItem.id)) {
        newInventory = [...prev.inventory, newItem];
        setUnlockedItem(newItem);
        message.success(`Hai ottenuto: ${newItem.name}!`);
      }
    }

    // Registra la decisione
    const decisionDetail = {
      emailId: updatedEmail.id,
      emailSubject: updatedEmail.subject,
      choiceText: selectedOption.text,
      effect: selectedOption.effect,
      timestamp: new Date(),
    };
    const newDecisionHistory = [...prev.decisionHistory, decisionDetail];

    // Trigger per eventi basati sulla risposta
    if (leveledUp) {
      receiveEmail('level_up');
    }
    if (selectedOption.itemId) {
      receiveEmail('mission_completed');
    }

    return {
      ...prev,
      emails: updatedEmails,
      emailResponses: newEmailResponses,
      competencies: newCompetencies,
      relationships: newRelationships,
      xp: newXp,
      level: newLevel,
      xpToNextLevel: newXpToNextLevel,
      inventory: newInventory,
      decisionHistory: newDecisionHistory,
    };
  });
};


  // Funzione per controllare e sbloccare gli achievements
  const checkAchievements = () => {
    // Esempio: se la competenza Comunicazione supera 80
    if (gameState.competencies['Comunicazione'] >= 80 && !gameState.unlockedAchievements.includes(1)) {
      setGameState(prev => ({
        ...prev,
        unlockedAchievements: [...prev.unlockedAchievements, 1],
        inventory: [...prev.inventory, {
          id: 'achievement_1',
          name: 'Comunicatore Efficace',
          description: 'Hai migliorato la tua comunicazione di almeno 80 punti.',
          type: 'achievement',
          icon: '🗣️',
        }],
      }));
      message.success('Hai sbloccato l\'achievement "Comunicatore Efficace"!');
    }
    // Aggiungi altre condizioni per altri achievements
  };

  // Hook per controllare gli achievements dopo ogni aggiornamento dello stato
  useEffect(() => {
    checkAchievements();
  }, [gameState.competencies, gameState.relationships, gameState.missions]);

  // Funzioni per gestire l'apertura e la chiusura dell'inventario
  const handleInventoryOpen = () => {
    setInventoryVisible(true);
  };

  const handleInventoryClose = () => {
    setInventoryVisible(false);
  };

  // Funzione per rendere lo scenario corrente
  const renderCurrentScenario = () => {
    const currentScenario = initialScenarios.find(scenario => scenario.id === gameState.currentScenarioId);

    if (!currentScenario) {
      return null; // O gestisci l'errore in modo appropriato
    }

    return (
      <Card
        title={`Scenario: ${currentScenario.title}`}
      >
        <Paragraph>{currentScenario.description}</Paragraph>
        <Space direction="vertical" style={{ width: '100%' }}>
          {currentScenario.options
            .filter(option => {
              // Gestione di requiredCompetency
              if (option.requiredCompetency) {
                return Object.entries(option.requiredCompetency).every(([competency, condition]) => {
                  const playerCompetency = gameState.competencies[competency] || 0;
                  if (condition.min !== undefined && playerCompetency < condition.min) {
                    return false;
                  }
                  if (condition.max !== undefined && playerCompetency > condition.max) {
                    return false;
                  }
                  return true;
                });
              }
              // Gestione di requiredItemId
              if (option.requiredItemId) {
                return gameState.inventory.some(item => item.id === option.requiredItemId);
              }
              return true;
            })
            .map((option, index) => (
              <Button
                key={option.id}
                onClick={() => handleScenarioChoice(option)}
                block
              >
                {option.text}
              </Button>
            ))}
        </Space>
      </Card>
    );
  };

  // Funzione per segnare un'email come letta
  // Funzione per segnare un'email come letta
  const handleMarkAsRead = (emailId) => {
    setGameState(prev => {
      const emailIndex = prev.emails.findIndex(e => e.id === emailId);
      if (emailIndex === -1) return prev;

      const updatedEmail = {
        ...prev.emails[emailIndex],
        isRead: true,
      };

      const updatedEmails = [...prev.emails];
      updatedEmails[emailIndex] = updatedEmail;

      return {
        ...prev,
        emails: updatedEmails,
      };
    });
  };


  return (
    <Layout>
      <Header>
        <Title level={3} style={{ color: 'white', margin: 0 }}>Leadership RPG Simulator</Title>
      </Header>
      <Content style={{ padding: '24px' }}>
        {showCharacterCreation && (
          <Modal
            title="Creazione del Personaggio"
            visible={showCharacterCreation}
            footer={null}
            closable={false}
          >
            <Input
              placeholder="Inserisci il tuo nome"
              value={playerName}
              onChange={(e) => setPlayerName(e.target.value)}
              style={{ marginBottom: '16px' }}
            />
            <Select
              placeholder="Seleziona un avatar"
              style={{ width: '100%', marginBottom: '16px' }}
              onChange={(value) => setSelectedAvatar(avatars.find(avatar => avatar.name === value))}
            >
              {avatars.map((avatar, index) => (
                <Option key={avatar.name} value={avatar.name}>
                  {avatar.icon} {avatar.name}
                </Option>
              ))}
            </Select>
            <Button type="primary" block onClick={startGame}>
              Inizia l'Avventura
            </Button>
          </Modal>
        )}

        {!showCharacterCreation && !showSummary && (
          <>
            {/* Sezione Superiore: Informazioni Utente e Bottone Inventario */}
            <div className="user-info-container">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar size={64}>{gameState.character.avatar.icon}</Avatar>
                <div style={{ marginLeft: '16px' }}>
                  <Title level={4} style={{ margin: 0 }}>{gameState.character.name}</Title>
                  <Paragraph style={{ margin: 0 }}>Livello {gameState.level}</Paragraph>
                </div>
              </div>
              <div>
                <Button type="primary" onClick={handleInventoryOpen} style={{ marginRight: '8px' }}>
                  Apri Inventario
                </Button>
                <Button type="primary" onClick={handleRelationshipsOpen}>
                  Relazioni
                </Button>
                <Button type="danger" onClick={restartGame} style={{ marginLeft: '8px' }}>
                  Nuovo Gioco
                </Button>
              </div>
            </div>
            {/* Sezione delle Competenze */}
            <div className="competencies-row">
              {Object.keys(gameState.competencies).map(key => (
                <div key={key} className="competency-box">
                  <Text strong>{key}</Text>
                  <Progress
                    percent={gameState.competencies[key]}
                    format={percent => `${percent}/100`}
                    strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    }}
                  />
                </div>
              ))}
            </div>

            {/* Sezione Inferiore: Scenari ed Email */}
            <div className="bottom-section">
              <div className="scenario-column">
                {/* Scenari */}
                {renderCurrentScenario()}
              </div>
              <div className="email-column">
                {/* Email */}
                <Emails
                  emails={gameState.emails}
                  handleRespondEmail={handleRespondEmail}
                  characterName={gameState.character.name}
                  handleMarkAsRead={handleMarkAsRead} // Passa la funzione per segnare come letto
                />
              </div>
            </div>

            {/* Modal per l'inventario */}
            {inventoryVisible && (
              <Modal
                visible={true}
                title="Inventario"
                onCancel={handleInventoryClose}
                footer={null}
              >
                <Inventory
                  items={gameState.inventory}
                  onUseItem={handleUseItem}
                  onClose={handleInventoryClose}
                />
              </Modal>
            )}
            {relationshipsVisible && (
              <Modal
                visible={true}
                title="Relazioni"
                onCancel={handleRelationshipsClose}
                footer={null}
              >
                <Relationships
                  relationships={gameState.relationships}
                  onClose={handleRelationshipsClose}
                />
              </Modal>
            )}

            {/* Modal per l'oggetto sbloccato */}
            {unlockedItem && (
              <Modal
                visible={true}
                title={`Hai ottenuto: ${unlockedItem.name}`}
                onCancel={() => setUnlockedItem(null)}
                footer={[
                  <Button key="close" type="primary" onClick={() => setUnlockedItem(null)}>
                    Chiudi
                  </Button>,
                ]}
              >
                <div style={{ textAlign: 'center' }}>
                  <div style={{ fontSize: '64px', marginBottom: '16px' }}>{unlockedItem.icon}</div>
                  <Paragraph>{unlockedItem.description}</Paragraph>
                </div>
              </Modal>
            )}
          </>
        )}

        {showSummary && reportData && (
          <Card title="Report Finale" style={{ marginTop: '24px' }}>
            <Title level={4}>Congratulazioni, {reportData.playerName}!</Title>
            <Paragraph>Hai completato l'avventura al livello {reportData.level}.</Paragraph>
            <Paragraph>Tempo totale impiegato: {formatTime(reportData.totalTime)}</Paragraph>

            <Divider />

            <Title level={4}>Analisi delle Competenze</Title>
            <div className="competencies-row">
              {Object.entries(reportData.competenciesScores).map(([competency, score]) => (
                <div key={competency} className="competency-box">
                  <Text strong>{competency}</Text>
                  <Progress
                    percent={score}
                    format={percent => `${percent}/100`}
                    strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    }}
                  />
                </div>
              ))}
            </div>

            <Divider />

            <Title level={4}>Punti di Forza</Title>
            {reportData.strengths.length > 0 ? (
              <ul>
                {reportData.strengths.map(item => (
                  <li key={item.competency}>
                    <Text strong>{item.competency}</Text>: {item.score}/100
                  </li>
                ))}
              </ul>
            ) : (
              <Paragraph>Non sono stati identificati punti di forza significativi.</Paragraph>
            )}

            <Divider />

            <Title level={4}>Aree di Miglioramento</Title>
            {reportData.improvements.length > 0 ? (
              <ul>
                {reportData.improvements.map(item => (
                  <li key={item.competency}>
                    <Text strong>{item.competency}</Text>: {item.score}/100
                  </li>
                ))}
              </ul>
            ) : (
              <Paragraph>Non sono state identificate aree di miglioramento significative.</Paragraph>
            )}

            <Divider />

            <Title level={4}>Decisioni Chiave</Title>
            <List
              itemLayout="vertical"
              dataSource={reportData.decisionHistory}
              renderItem={decision => (
                <List.Item key={decision.timestamp}>
                  <List.Item.Meta
                    title={decision.scenarioTitle}
                    description={`Data e Ora: ${new Date(decision.timestamp).toLocaleString()}`}
                  />
                  <Paragraph><strong>Scelta:</strong> {decision.choiceText}</Paragraph>
                  <Paragraph><strong>Effetto:</strong> {formatEffect(decision.effect)}</Paragraph>
                </List.Item>
              )}
            />

            {/* Pulsante per riavviare il gioco */}
            <Button onClick={restartGame} type="primary" style={{ marginTop: '24px' }}>
              Gioca di Nuovo
            </Button>
          </Card>
        )}

      </Content>
    </Layout>
  );
};

export default LeadershipSimulator;
