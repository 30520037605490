import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Assessment from './pages/Assessment';
import Chat from './components/Chat';
import AssessmentForm from './components/AssessmentForm';
import Register from './components/Register';  // Importa il componente Register
import Login from './components/Login';  // Importa il componente Login
import AssessmentDetail from './components/AssessmentDetail';
import UserDetail from './components/UserDetail'; // Importa il nuovo componente
import Layout from './components/Layout';  // Importa il layout globale
import { AchievementProvider } from './components/AchievementContext'; 
import './styles/theme.css'; // Importa il tema globale
import LeadershipSimulator from './components/gamification/LeadershipSimulator/components/LeadershipSimulator';
import ESGMetropolis from './components/gamification/ESGMetropolis/ESGMetropolis'
import PeaceArchitect from './components/gamification/PeaceArchitect/UNMission'



// Importa il context per gli achievement

function App() {
    return (
        <AchievementProvider> {/* Wrappa l'applicazione nel context */}
            <Router>
                {/* Wrappa tutte le route nel layout globale */}
                <Routes>
                    <Route element={<Layout />}>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/assessment" element={<Assessment />} />
                        <Route path="/chat/:id" element={<Chat />} />
                        <Route path="/assessment/:id" element={<AssessmentDetail />} />
                        <Route path="/gamification" element={<LeadershipSimulator />} />
                        <Route path="/ESGMetropolis" element={<ESGMetropolis />} />
                        <Route path="/PeaceArchitect" element={<PeaceArchitect />} />
                        <Route path="/chat/" element={<Chat />} />
                        <Route path="/create-assessment" element={<AssessmentForm />} />
                        <Route path="/user/:userId" element={<UserDetail />} /> {/* Nuova rotta */}
                        <Route path="/register" element={<Register />} /> {/* Rotta per la registrazione */}
                        <Route path="/login" element={<Login />} /> {/* Rotta per il login */}
                    </Route>
                </Routes>
            </Router>
        </AchievementProvider>
    );
}

export default App;