import React from 'react';
import styled from 'styled-components';

// Styling del box di condivisione
const ShareBox = styled.div`
  margin-top: 5px;
  padding: 8px;
  background-color: #333;
  color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  min-height: 60px;
`;

// Styling del contenitore del link
const ShareLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

// Styling per l'input che mostra il link
const ShareInput = styled.input`
  border: none;
  padding: 4px;
  background-color: #444;
  color: #fff;
  border-radius: 5px;
  width: 90%;
`;

// Styling per il pulsante di copia
const CopyButton = styled.button`
  background-color: #57534e;
  color: #fff;
  padding: 4px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #292524;
  }
`;

// Componente ShareLinkComponent
function ShareLinkComponent({ link }) {
  const fakeLink = "https://demo.kimpy.com/chat"; // Link finto per l'aspetto estetico

  const handleCopy = () => {
    navigator.clipboard.writeText(link);  // Copia il vero link negli appunti
  };

  return (
    <ShareBox>
      <h3 style={{ marginBottom: '5px' }}>Condividi con il tuo team</h3>
      <ShareLink>
        <ShareInput type="text" value={fakeLink} readOnly />  {/* Mostra il link finto */}
        <CopyButton onClick={handleCopy}>
          📋
        </CopyButton>
      </ShareLink>
    </ShareBox>
  );
}

export default ShareLinkComponent;
