import React, { useState, useEffect } from 'react';
import { 
  Layout, 
  Card, 
  Button, 
  Progress, 
  Modal, 
  Typography, 
  Statistic, 
  Row, 
  Col, 
  Menu, 
  Avatar, 
  List, 
  Badge, 
  Drawer 
} from 'antd';
import { 
  UserOutlined, 
  BankOutlined, 
  ToolOutlined, 
  BellOutlined 
} from '@ant-design/icons';

const { Header, Content, Sider } = Layout;
const { Title, Paragraph } = Typography;

const PeaceArchitect = () => {
  const [gameState, setGameState] = useState({
    day: 1,
    character: {
      name: "Alex Peace",
      role: "UN Peacekeeper",
      experience: 0,
      skills: {
        diplomacy: 1,
        leadership: 1,
        strategy: 1,
        humanitarian: 1,
      }
    },
    missionStats: {
      stability: 50,
      civilianTrust: 50,
      diplomaticProgress: 0,
      resources: 1000000,
      personnel: 1000,
    },
    projects: [],
    events: [],
    notifications: [],
  });

  const [currentView, setCurrentView] = useState('overview');
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [notificationsVisible, setNotificationsVisible] = useState(false);

  // Avanza un giorno ogni 5 secondi (puoi regolare questo valore)
  useEffect(() => {
    const interval = setInterval(() => {
      updateGame();
    }, 5000);
    return () => clearInterval(interval);
  }, [gameState.day]);

  const updateGame = () => {
    setGameState(prev => ({
      ...prev,
      day: prev.day + 1,
      missionStats: {
        ...prev.missionStats,
        stability: Math.max(0, Math.min(100, prev.missionStats.stability + (prev.missionStats.diplomaticProgress / 100) - (Math.random() * 5))),
        civilianTrust: Math.max(0, Math.min(100, prev.missionStats.civilianTrust + (prev.projects.length / 2) - (Math.random() * 3))),
        diplomaticProgress: Math.min(100, prev.missionStats.diplomaticProgress + (Math.random() * 2)),
        resources: prev.missionStats.resources - (prev.missionStats.personnel * 100) + (prev.missionStats.civilianTrust * 1000),
      },
      character: {
        ...prev.character,
        experience: prev.character.experience + 10,
      },
    }));

    if ((gameState.day + 1) % 7 === 0) { // +1 perché day è già incrementato
      triggerRandomEvent();
    }

    checkProjectsCompletion();
    checkLevelUp();
  };

  const checkLevelUp = () => {
    const expNeeded = gameState.character.experience;
    if (expNeeded >= 100) {
      setModalContent({
        title: "Livello Superato!",
        content: (
          <div>
            <p>Hai guadagnato abbastanza esperienza per migliorare le tue abilità. Scegli un'abilità da potenziare:</p>
            {Object.keys(gameState.character.skills).map(skill => (
              <Button 
                key={skill} 
                onClick={() => improveSkill(skill)} 
                style={{margin: '5px'}}
              >
                {skill.charAt(0).toUpperCase() + skill.slice(1)}
              </Button>
            ))}
          </div>
        )
      });
      setModalVisible(true);
    }
  };

  const improveSkill = (skill) => {
    setGameState(prev => ({
      ...prev,
      character: {
        ...prev.character,
        skills: {
          ...prev.character.skills,
          [skill]: prev.character.skills[skill] + 1
        },
        experience: prev.character.experience - 100
      }
    }));
    setModalVisible(false);
  };

  const projectTypes = {
    infrastructure: { 
      name: "Risanamento delle Infrastrutture", 
      duration: 30,
      cost: 100000, 
      effect: { stability: 5, civilianTrust: 10 },
      skillRequired: "strategy"
    },
    education: { 
      name: "Programma Educativo", 
      duration: 60,
      cost: 50000, 
      effect: { civilianTrust: 15, diplomaticProgress: 5 },
      skillRequired: "humanitarian"
    },
    security: { 
      name: "Formazione delle Forze di Sicurezza Locali", 
      duration: 45,
      cost: 200000, 
      effect: { stability: 15, personnel: -50 },
      skillRequired: "leadership"
    },
    medicalAid: { 
      name: "Assistenza Medica", 
      duration: 15,
      cost: 75000, 
      effect: { civilianTrust: 20, stability: 5 },
      skillRequired: "humanitarian"
    },
    diplomacy: { 
      name: "Conferenza di Pace", 
      duration: 7,
      cost: 150000, 
      effect: { diplomaticProgress: 20, stability: 10 },
      skillRequired: "diplomacy"
    }
  };

  const startProject = (type) => {
    const project = projectTypes[type];
    if (gameState.missionStats.resources >= project.cost) {
      setGameState(prev => ({
        ...prev,
        missionStats: {
          ...prev.missionStats,
          resources: prev.missionStats.resources - project.cost,
        },
        projects: [...prev.projects, { ...project, type, startDay: prev.day, completed: false }],
      }));
      addNotification(`Hai avviato un nuovo progetto: ${project.name}`);
    } else {
      addNotification(`Risorse insufficienti per avviare il progetto: ${project.name}`);
    }
  };

  const checkProjectsCompletion = () => {
    setGameState(prev => {
      let updatedStats = { ...prev.missionStats };
      let updatedProjects = prev.projects.map(project => {
        if (!project.completed && (prev.day - project.startDay) >= project.duration) {
          addNotification(`Progetto completato: ${project.name}`);
          // Applica gli effetti del progetto
          Object.keys(project.effect).forEach(key => {
            if (key in updatedStats) {
              updatedStats[key] = Math.max(0, Math.min(100, updatedStats[key] + project.effect[key]));
            } else {
              updatedStats[key] = project.effect[key];
            }
          });
          return { ...project, completed: true };
        }
        return project;
      });

      return {
        ...prev,
        missionStats: updatedStats,
        projects: updatedProjects,
      };
    });
  };

  const addNotification = (message) => {
    setGameState(prev => ({
      ...prev,
      notifications: [...prev.notifications, { message, day: prev.day }]
    }));
  };

  const triggerRandomEvent = () => {
    const eventList = [
      {
        title: "Escalatione del Conflitto",
        description: "Una nuova ondata di violenza minaccia la stabilità della regione. Come reagisci?",
        options: [
          { 
            text: "Aumenta la presenza militare", 
            effect: { stability: 10, civilianTrust: -5, resources: -100000, personnel: 100 },
            skillCheck: { type: "leadership", difficulty: 3 }
          },
          { 
            text: "Avvia negoziati d'emergenza", 
            effect: { diplomaticProgress: 15, stability: 5, resources: -50000 },
            skillCheck: { type: "diplomacy", difficulty: 4 }
          },
          { 
            text: "Implementa il coprifuoco", 
            effect: { stability: 15, civilianTrust: -10 },
            skillCheck: { type: "strategy", difficulty: 2 }
          }
        ]
      },
      {
        title: "Crisi Umanitaria",
        description: "Un disastro naturale ha colpito l'area della missione. Come gestisci la situazione?",
        options: [
          { 
            text: "Invia aiuti immediati", 
            effect: { civilianTrust: 20, resources: -200000, stability: 5 },
            skillCheck: { type: "humanitarian", difficulty: 3 }
          },
          { 
            text: "Coordina con ONG locali", 
            effect: { civilianTrust: 15, diplomaticProgress: 10, resources: -100000 },
            skillCheck: { type: "diplomacy", difficulty: 3 }
          },
          { 
            text: "Concentra sulle misure di sicurezza", 
            effect: { stability: 10, civilianTrust: -5, resources: -50000 },
            skillCheck: { type: "strategy", difficulty: 4 }
          }
        ]
      },
      {
        title: "Scandalo di Corruzione",
        description: "Sono emerse accuse di corruzione contro funzionari locali. Qual è la tua risposta?",
        options: [
          { 
            text: "Lancia un'indagine interna", 
            effect: { diplomaticProgress: -5, civilianTrust: 10, stability: -5 },
            skillCheck: { type: "leadership", difficulty: 4 }
          },
          { 
            text: "Collabora con autorità internazionali", 
            effect: { diplomaticProgress: 15, resources: -100000, stability: 5 },
            skillCheck: { type: "diplomacy", difficulty: 5 }
          },
          { 
            text: "Implementa nuove misure anti-corruzione", 
            effect: { civilianTrust: 15, stability: 10, resources: -150000 },
            skillCheck: { type: "strategy", difficulty: 3 }
          }
        ]
      }
    ];

    const randomEvent = eventList[Math.floor(Math.random() * eventList.length)];
    setModalContent({
      title: randomEvent.title,
      content: (
        <div>
          <p>{randomEvent.description}</p>
          {randomEvent.options.map((option, index) => (
            <Button 
              key={index} 
              onClick={() => handleEventChoice(option)}
              style={{margin: '5px'}}
            >
              {option.text}
            </Button>
          ))}
        </div>
      )
    });
    setModalVisible(true);
  };

  const handleEventChoice = (option) => {
    const skillCheck = option.skillCheck;
    const skillLevel = gameState.character.skills[skillCheck.type];
    const success = skillLevel >= skillCheck.difficulty;

    let effect = { ...option.effect };
    let resultMessage = "";

    if (success) {
      resultMessage = `La tua abilità in ${skillCheck.type} è stata sufficiente. L'operazione ha avuto successo!`;
      // Migliora gli effetti positivi o mitiga quelli negativi
      Object.keys(effect).forEach(key => {
        if (effect[key] > 0) {
          effect[key] = Math.round(effect[key] * 1.5);
        } else {
          effect[key] = Math.round(effect[key] * 0.5);
        }
      });
    } else {
      resultMessage = `La tua abilità in ${skillCheck.type} non è stata sufficiente. L'operazione ha avuto successo limitato.`;
      // Riduci gli effetti positivi o potenzia quelli negativi
      Object.keys(effect).forEach(key => {
        if (effect[key] > 0) {
          effect[key] = Math.round(effect[key] * 0.5);
        } else {
          effect[key] = Math.round(effect[key] * 1.5);
        }
      });
    }

    setGameState(prev => ({
      ...prev,
      missionStats: Object.keys(effect).reduce((acc, key) => {
        if (key in acc) {
          acc[key] = Math.max(0, Math.min(100, acc[key] + effect[key]));
        }
        return acc;
      }, { ...prev.missionStats }),
      character: {
        ...prev.character,
        experience: prev.character.experience + 20
      },
      events: [...prev.events, { day: prev.day, title: modalContent.title, choice: option.text, result: resultMessage }]
    }));

    addNotification(resultMessage);
    setModalVisible(false);
  };

  const renderContent = () => {
    switch(currentView) {
      case 'overview':
        return (
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Card title="Statistiche della Missione">
                <Statistic title="Stabilità" value={gameState.missionStats.stability} suffix="%" />
                <Statistic title="Fiducia Civile" value={gameState.missionStats.civilianTrust} suffix="%" />
                <Statistic title="Progresso Diplomatico" value={gameState.missionStats.diplomaticProgress} suffix="%" />
                <Statistic title="Risorse" value={gameState.missionStats.resources} prefix="$" />
                <Statistic title="Personale" value={gameState.missionStats.personnel} />
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Progetti Attivi">
                <List
                  dataSource={gameState.projects.filter(p => !p.completed)}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.name}
                        description={`Giorno ${gameState.day - item.startDay}/${item.duration}`}
                      />
                      <Progress 
                        percent={Math.min(100, ((gameState.day - item.startDay) / item.duration) * 100)} 
                        size="small" 
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card title="Eventi Recenti">
                <List
                  dataSource={gameState.events.slice(-5).reverse()}
                  renderItem={item => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.title}
                        description={`Giorno ${item.day}: ${item.choice} - ${item.result}`}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            </Col>
          </Row>
        );
      case 'character':
        return (
          <Card title="Scheda Personaggio">
            <Avatar size={64} icon={<UserOutlined />} />
            <Title level={4}>{gameState.character.name}</Title>
            <Paragraph>{gameState.character.role}</Paragraph>
            <Progress 
              percent={Math.floor((gameState.character.experience % 100) / 100 * 100)} 
              format={() => `Livello ${Math.floor(gameState.character.experience / 100)}`} 
            />
            <Title level={5}>Abilità</Title>
            {Object.entries(gameState.character.skills).map(([skill, level]) => (
              <Row key={skill} style={{ marginBottom: '10px' }}>
                <Col span={12}>{skill.charAt(0).toUpperCase() + skill.slice(1)}</Col>
                <Col span={12}>
                  <Progress percent={level * 20} size="small" />
                </Col>
              </Row>
            ))}
          </Card>
        );
      case 'projects':
        return (
          <Card title="Avvia un Nuovo Progetto">
            <Row gutter={[16, 16]}>
              {Object.entries(projectTypes).map(([key, project]) => (
                <Col span={8} key={key}>
                  <Card 
                    title={project.name} 
                    extra={
                      <Button 
                        type="primary" 
                        onClick={() => startProject(key)} 
                        disabled={gameState.missionStats.resources < project.cost}
                      >
                        Avvia
                      </Button>
                    }
                  >
                    <p><strong>Costo:</strong> ${project.cost.toLocaleString()}</p>
                    <p><strong>Durata:</strong> {project.duration} giorni</p>
                    <p><strong>Abilità Richiesta:</strong> {project.skillRequired.charAt(0).toUpperCase() + project.skillRequired.slice(1)}</p>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        );
      default:
        return <div>Vista non disponibile</div>;
    }
  };

  const handleMenuClick = (e) => {
    setCurrentView(e.key);
  };

  const notificationCount = gameState.notifications.length;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible>
        <div style={{ height: '32px', margin: '16px', color: 'white', textAlign: 'center', fontSize: '18px' }}>
          Peace Architect
        </div>
        <Menu 
          theme="dark" 
          mode="inline" 
          selectedKeys={[currentView]} 
          onClick={handleMenuClick}
        >
          <Menu.Item key="overview" icon={<BankOutlined />}>
            Panoramica
          </Menu.Item>
          <Menu.Item key="character" icon={<UserOutlined />}>
            Personaggio
          </Menu.Item>
          <Menu.Item key="projects" icon={<ToolOutlined />}>
            Progetti
          </Menu.Item>
          {/* Puoi aggiungere altre voci di menu qui */}
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ background: '#fff', padding: 0, paddingRight: '20px', textAlign: 'right' }}>
          <Badge count={notificationCount} overflowCount={99}>
            <BellOutlined style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => setNotificationsVisible(true)} />
          </Badge>
        </Header>
        <Content style={{ margin: '16px' }}>
          {renderContent()}
        </Content>
      </Layout>

      {/* Modal per Eventi e Livelli Superati */}
      <Modal
        title={modalContent?.title}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        {modalContent?.content}
      </Modal>

      {/* Drawer per Notifiche */}
      <Drawer
        title="Notifiche"
        placement="right"
        onClose={() => setNotificationsVisible(false)}
        visible={notificationsVisible}
        width={350}
      >
        <List
          dataSource={gameState.notifications.slice().reverse()}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={`Giorno ${item.day}`}
                description={item.message}
              />
            </List.Item>
          )}
        />
      </Drawer>
    </Layout>
  );
};

export default PeaceArchitect;
