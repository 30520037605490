import React from 'react';
import { Card, Typography, Row, Col } from 'antd';
import styled from 'styled-components';

const { Text, Title } = Typography;

const StatisticCardContainer = styled(Card)`
  padding: 16px;
  margin: 10px 0;
  background-color: #fff; /* Sfondo bianco */
  color: #333; /* Colore del testo scuro */
  border-radius: 8px; /* Angoli arrotondati */
  transition: all 0.3s ease-in-out;
  border: 1px solid #e7e5e4; /* Bordo sottile */

  &:hover {
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
    background-color: #f0f4ff; /* Sfondo leggermente diverso in hover */
    border: 1px solid #e7e5e4; /* Bordo invariato */
  }
`;

const IconContainer = styled.div`
  font-size: 28px;
  color: #007aff; /* Colore dell'icona */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 122, 255, 0.1); /* Sfondo chiaro per l'icona */
  border-radius: 50%;
`;

const StatNumber = styled(Title)`
  && {
    font-size: 28px;
    font-weight: bold;
    color: #333; /* Testo scuro */
    margin-bottom: 0;
  }
`;

const StatLabel = styled(Text)`
  && {
    font-size: 14px;
    color: #555; /* Colore per la descrizione */
  }
`;

function StatisticCard({ icon, number, label }) {
  return (
    <StatisticCardContainer bordered={false} hoverable>
      <Row align="middle">
        <Col flex="50px">
          <IconContainer>{icon}</IconContainer>
        </Col>
        <Col flex="auto">
          <StatNumber level={3}>{number}</StatNumber>
          <StatLabel>{label}</StatLabel>
        </Col>
      </Row>
    </StatisticCardContainer>
  );
}

export default StatisticCard;
