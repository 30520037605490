// src/components/Emails.js

import React, { useState } from 'react';
import { Card, Button, Modal, List, Typography, Input } from 'antd';

const { Paragraph, Text } = Typography;

const Emails = ({ emails, handleRespondEmail, characterName, handleMarkAsRead }) => {
  console.log('Emails component - emails prop:', emails);
  
  const [visibleEmail, setVisibleEmail] = useState(null);
  const [textResponse, setTextResponse] = useState('');

  const openEmail = (email) => {
    setVisibleEmail(email);
    if (handleMarkAsRead) {
      handleMarkAsRead(email.id);
    }
  };

  const closeEmail = () => {
    setVisibleEmail(null);
    setTextResponse('');
  };

  const handleOptionClick = (option) => {
    if (visibleEmail) {
      handleRespondEmail(visibleEmail.id, option);
      closeEmail();
    }
  };

  const handleTextSubmit = () => {
    if (visibleEmail && textResponse.trim() !== '') {
      handleRespondEmail(visibleEmail.id, { text: textResponse.trim() });
      closeEmail();
    }
  };

  return (
    <Card title="Messaggi Ricevuti" style={{ marginTop: '0px' }}>
      {emails && emails.length > 0 ? (
        <List
          itemLayout="horizontal"
          dataSource={emails}
          renderItem={email => (
            <List.Item key={email.id}>
              <List.Item.Meta
                title={<a onClick={() => openEmail(email)}>{email.subject}</a>}
                description={`Da: ${email.sender} ${email.isRead ? '(Letto)' : '(Nuovo)'}`}
              />
            </List.Item>
          )}
        />
      ) : (
        <Paragraph>Non hai nuovi messaggi.</Paragraph>
      )}

      {visibleEmail && (
        <Modal
          visible={true}
          title={visibleEmail.subject}
          onCancel={closeEmail}
          footer={null}
        >
          <Paragraph><strong>Da:</strong> {visibleEmail.sender}</Paragraph>
          <Paragraph>{visibleEmail.content.replace('[Nome]', characterName)}</Paragraph>

          {visibleEmail.userResponse ? (
            <>
              <Paragraph><strong>La tua risposta:</strong> {visibleEmail.userResponse}</Paragraph>
              <Button type="primary" onClick={closeEmail} block>
                Chiudi
              </Button>
            </>
          ) : (
            <>
              {visibleEmail.type === 'options' && (
                <>
                  <Paragraph><strong>Scegli una risposta:</strong></Paragraph>
                  {visibleEmail.options && visibleEmail.options.length > 0 ? (
                    visibleEmail.options.map((option, index) => (
                      <Button
                        key={index}
                        onClick={() => handleOptionClick(option)}
                        style={{ marginBottom: '8px' }}
                        block
                      >
                        {option.text}
                      </Button>
                    ))
                  ) : (
                    <Paragraph>Nessuna opzione disponibile.</Paragraph>
                  )}
                </>
              )}

              {visibleEmail.type === 'text' && (
                <>
                  <Paragraph><strong>Inserisci la tua risposta:</strong></Paragraph>
                  <Input
                    placeholder="Scrivi la tua risposta qui..."
                    value={textResponse}
                    onChange={(e) => setTextResponse(e.target.value)}
                    onPressEnter={handleTextSubmit}
                  />
                  <Button
                    type="primary"
                    onClick={handleTextSubmit}
                    style={{ marginTop: '8px' }}
                    block
                    disabled={textResponse.trim() === ''}
                  >
                    Invia
                  </Button>
                </>
              )}
            </>
          )}
        </Modal>
      )}
    </Card>
  );
};

export default Emails;
