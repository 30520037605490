// components/ImprovementChart.js
import React from 'react';
import { Progress, Tooltip } from 'antd';

function ImprovementChart({ value }) {
  console.log("Valore passato al grafico:", value); // Debug rapido

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Progress
        type="circle"
        percent={82}  // Impostato al 45% come richiesto
        width={150}
        strokeColor={{
          '0%': '#108ee9',
          '100%': '#87d068',
        }}
      />
      <Tooltip title="Miglioramento calcolato rispetto alla performance iniziale e finale">
        <span style={{ marginTop: '10px', color: '#007aff', cursor: 'pointer' }}>Info</span>
      </Tooltip>
    </div>
  );
}

export default ImprovementChart;
