import React, { useState } from 'react';
import { Card, Button, Modal, Typography, message } from 'antd';

const Facilities = ({ gameState, facilityTypes, addFacility, upgradeFacility }) => { // Aggiungi upgradeFacility come prop
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [facilityToUpgrade, setFacilityToUpgrade] = useState(null);

  return (
    <div>
      <Card title="Impianti Disponibili">
        {Object.keys(facilityTypes).map((type) => (
          <Card key={type} type="inner" title={facilityTypes[type].name} style={{ marginBottom: 16 }}>
            <Typography.Paragraph>Costo: €{facilityTypes[type].cost.toLocaleString()}</Typography.Paragraph>
            {facilityTypes[type].maxCapacity && (
              <Typography.Paragraph>Capacità Massima: {facilityTypes[type].maxCapacity} unità</Typography.Paragraph>
            )}
            {facilityTypes[type].efficiency && (
              <Typography.Paragraph>Efficienza: {facilityTypes[type].efficiency}%</Typography.Paragraph>
            )}
            <Button 
              onClick={() => setSelectedFacility(type)} 
              disabled={gameState.money < facilityTypes[type].cost}
            >
              Costruisci
            </Button>
          </Card>
        ))}
      </Card>

      <Card title="Impianti Costruiti" style={{ marginTop: 16 }}>
        {gameState.facilities.length > 0 ? (
          gameState.facilities.map((facility, index) => (
            <Card key={index} type="inner" title={facilityTypes[facility.type].name} style={{ marginBottom: 16 }}>
              <Typography.Paragraph>Tipo: {facilityTypes[facility.type].name}</Typography.Paragraph>
              <Typography.Paragraph>Livello: {facility.level}</Typography.Paragraph>
              <Typography.Paragraph>Efficienza: {facility.efficiency}%</Typography.Paragraph>
              <Typography.Paragraph>Capacità Massima: {facility.maxCapacity} unità</Typography.Paragraph>
              <Button 
                onClick={() => setFacilityToUpgrade(index)} 
                style={{ marginTop: 8 }}
              >
                Migliora Impianto
              </Button>
            </Card>
          ))
        ) : (
          <Typography.Paragraph>Nessun impianto costruito.</Typography.Paragraph>
        )}
      </Card>

      {/* Modal per confermare la costruzione */}
      {selectedFacility && (
        <Modal
          visible={true}
          title={`Costruisci ${facilityTypes[selectedFacility].name}`}
          onCancel={() => setSelectedFacility(null)}
          onOk={() => {
            addFacility(selectedFacility);
            setSelectedFacility(null);
          }}
        >
          <Typography.Paragraph>
            Confermi la costruzione di questo impianto per €{facilityTypes[selectedFacility].cost.toLocaleString()}?
          </Typography.Paragraph>
        </Modal>
      )}

      {/* Modal per migliorare l'impianto */}
      {facilityToUpgrade !== null && (
        <Modal
          visible={true}
          title={`Migliora ${facilityTypes[gameState.facilities[facilityToUpgrade].type].name}`}
          onCancel={() => setFacilityToUpgrade(null)}
          onOk={() => {
            upgradeFacility(facilityToUpgrade);
            setFacilityToUpgrade(null);
          }}
        >
          <Typography.Paragraph>
            Confermi il miglioramento di questo impianto per €{(facilityTypes[gameState.facilities[facilityToUpgrade].type].cost / 2).toLocaleString()}?
          </Typography.Paragraph>
        </Modal>
      )}
    </div>
  );
};

export default Facilities;
