// src/components/gamification/LeadershipSimulator/data/scenarios.js

export const initialScenarios = [
  // Scenari Attuali (ID 1-34)
  {
    id: 1,
    title: "Il Nuovo Inizio",
    description:
      "Sei stato appena promosso a manager di un team. Come intendi presentarti al tuo nuovo team?",
    options: [
      {
        text: "Organizzi una riunione informale per conoscere meglio i membri del team.",
        effect: { Comunicazione: 10, Empatia: 10 },
        xpGained: 50,
        nextScenarioId: 2,
      },
      {
        text: "Invii un'email dettagliata con le tue aspettative e obiettivi.",
        effect: { Comunicazione: 5, Leadership: 5 },
        xpGained: 40,
        nextScenarioId: 3,
      },
      {
        text: "Ti concentri sul lavoro e interagisci solo quando necessario.",
        effect: { Empatia: -10, Comunicazione: -5 },
        xpGained: 30,
        nextScenarioId: 4,
      },
    ],
  },
  {
    id: 2,
    title: "La Prima Sfida",
    description:
      "Uno dei membri del tuo team si avvicina con un problema personale che sta influenzando il suo lavoro.",
    options: [
      {
        text: "Offri supporto e flessibilità per aiutarlo a superare il momento difficile.",
        effect: { Empatia: 15, Leadership: 5 },
        xpGained: 60,
        nextScenarioId: 5,
        itemId: "gratitude_letter",
      },
      {
        text: "Suggerisci di separare i problemi personali dal lavoro.",
        effect: { Comunicazione: -5, Empatia: -10 },
        xpGained: 40,
        nextScenarioId: 6,
      },
    ],
  },
  {
    id: 3,
    title: "Obiettivi Ambiziosi",
    description:
      "Hai stabilito obiettivi elevati per il tuo team, ma sembrano essere troppo ambiziosi.",
    options: [
      {
        text: "Rivedi gli obiettivi insieme al team per renderli più realistici.",
        effect: { Leadership: 10, "Problem Solving": 10 },
        xpGained: 50,
        nextScenarioId: 5,
      },
      {
        text: "Insisti sugli obiettivi originali, spingendo il team a dare il massimo.",
        effect: { Leadership: -5, "Gestione del Tempo": -10 },
        xpGained: 40,
        nextScenarioId: 6,
      },
    ],
  },
  {
    id: 4,
    title: "Disconnessione",
    description:
      "Noti che il tuo team non ti vede come un leader accessibile.",
    options: [
      {
        text: "Inizi a dedicare del tempo per interagire con il team e costruire rapporti.",
        effect: { Empatia: 15, Comunicazione: 10 },
        xpGained: 60,
        nextScenarioId: 5,
      },
      {
        text: "Continui a focalizzarti sui risultati, credendo che il tempo migliorerà le cose.",
        effect: { Empatia: -10, Comunicazione: -5 },
        xpGained: 30,
        nextScenarioId: 6,
      },
    ],
  },
  {
    id: 5,
    title: "Progetto Cruciale",
    description:
      "Ti viene assegnato un progetto importante con una scadenza ravvicinata.",
    options: [
      {
        text: "Organizzi il team e deleghi compiti in base alle competenze individuali.",
        effect: { Leadership: 15, "Gestione del Tempo": 10 },
        xpGained: 70,
        nextScenarioId: 7,
        itemId: "efficiency_medal",
      },
      {
        text: "Decidi di gestire la maggior parte del lavoro da solo per assicurarti che tutto sia perfetto.",
        effect: { Leadership: -10, "Gestione del Tempo": -15 },
        xpGained: 40,
        nextScenarioId: 8,
      },
    ],
  },
  {
    id: 6,
    title: "Team Demotivato",
    description:
      "Il tuo team mostra segni di demotivazione e calo di produttività.",
    options: [
      {
        text: "Organizzi un meeting per discutere apertamente delle preoccupazioni del team.",
        effect: { Empatia: 10, Comunicazione: 15 },
        xpGained: 50,
        nextScenarioId: 7,
      },
      {
        text: "Imponi nuove regole e monitoraggi più stretti per aumentare la produttività.",
        effect: { Leadership: -5, Empatia: -10 },
        xpGained: 30,
        nextScenarioId: 8,
      },
    ],
  },
  {
    id: 7,
    title: "Successo del Progetto",
    description:
      "Il progetto è stato un successo grazie al lavoro di squadra.",
    options: [
      {
        text: "Celebri il successo con il team e riconosci i contributi individuali.",
        effect: { Comunicazione: 15, Leadership: 10 },
        xpGained: 80,
        nextScenarioId: 9,
        itemId: "team_photo",
      },
      {
        text: "Prendi il merito principale del successo davanti ai superiori.",
        effect: { Empatia: -15, Comunicazione: -10 },
        xpGained: 50,
        nextScenarioId: 10,
      },
    ],
  },
  {
    id: 8,
    title: "Fallimento del Progetto",
    description:
      "Il progetto non ha raggiunto gli obiettivi prefissati.",
    options: [
      {
        text: "Assumi la responsabilità e lavori con il team per identificare le aree di miglioramento.",
        effect: { Leadership: 10, "Problem Solving": 15 },
        xpGained: 70,
        nextScenarioId: 9,
      },
      {
        text: "Incolpi il team per la mancanza di risultati.",
        effect: { Empatia: -20, Comunicazione: -15 },
        xpGained: 30,
        nextScenarioId: 10,
      },
    ],
  },
  {
    id: 9,
    title: "Offerta di Promozione",
    description:
      "Grazie ai tuoi successi, ti viene offerta una promozione.",
    options: [
      {
        text: "Accetti la promozione e ringrazi il team per il supporto.",
        effect: { Leadership: 15, Empatia: 10 },
        xpGained: 100,
        nextScenarioId: 11,
        itemId: "director_title",
      },
      {
        text: "Chiedi tempo per considerare l'offerta, valutando l'impatto sul team.",
        effect: { "Problem Solving": 10, "Gestione del Tempo": 5 },
        xpGained: 80,
        nextScenarioId: 12,
      },
    ],
  },
  {
    id: 10,
    title: "Richiamo dai Superiori",
    description:
      "I tuoi superiori esprimono preoccupazione per la gestione del team.",
    options: [
      {
        text: "Prendi atto del feedback e lavori per migliorare le tue competenze di leadership.",
        effect: { Leadership: 10, Empatia: 5 },
        xpGained: 60,
        nextScenarioId: 13,
        itemId: "leadership_manual",
      },
      {
        text: "Ignori le critiche, credendo che il tuo approccio sia corretto.",
        effect: { Leadership: -10, Comunicazione: -10 },
        xpGained: 20,
        nextScenarioId: 14,
      },
    ],
  },
  // ... (scenari 11-34 come forniti dall'utente)
  // Assicurati di includere tutti gli scenari fino a ID 34
  {
    id: 11,
    title: "Nuove Responsabilità",
    description:
      "Come nuovo direttore, ti viene chiesto di guidare un progetto internazionale.",
    options: [
      {
        text: "Accetti la sfida e inizi a pianificare con cura.",
        effect: { Leadership: 15, "Gestione del Tempo": 10 },
        xpGained: 100,
        nextScenarioId: 15,
        itemId: "organizer_agenda",
      },
      {
        text: "Delegare la responsabilità a un altro manager per concentrarti su altri compiti.",
        effect: { Empatia: -10, Leadership: -5 },
        xpGained: 50,
        nextScenarioId: 16,
      },
    ],
  },
  {
    id: 12,
    title: "Riflessione Personale",
    description:
      "Prendi del tempo per riflettere sulla proposta di promozione e sul futuro del team.",
    options: [
      {
        text: "Decidi di accettare la promozione e pianifichi una strategia per sostenere il team.",
        effect: { "Problem Solving": 15, Empatia: 10 },
        xpGained: 90,
        nextScenarioId: 11,
      },
      {
        text: "Rifiuti la promozione per continuare a lavorare a stretto contatto con il team.",
        effect: { Empatia: 20, Comunicazione: 10 },
        xpGained: 80,
        nextScenarioId: 17,
      },
    ],
  },
  {
    id: 13,
    title: "Auto-Miglioramento",
    description:
      "Utilizzi il Manuale di Leadership per migliorare le tue competenze.",
    options: [
      {
        text: "Applichi i consigli del manuale nel lavoro quotidiano.",
        effect: { Leadership: 10, "Problem Solving": 10 },
        xpGained: 70,
        nextScenarioId: 15,
      },
    ],
    requiredItemId: "leadership_manual",
  },
  {
    id: 14,
    title: "Conseguenze delle Azioni",
    description:
      "Il tuo approccio rigido ha portato a ulteriori problemi nel team.",
    options: [
      {
        text: "Decidi di cambiare atteggiamento e cerchi di riconquistare la fiducia del team.",
        effect: { Empatia: 15, Comunicazione: 15 },
        xpGained: 60,
        nextScenarioId: 13,
      },
      {
        text: "Continui sulla stessa strada, ignorando il malcontento.",
        effect: { Leadership: -15, Empatia: -10 },
        xpGained: 20,
        nextScenarioId: 18,
      },
    ],
  },
  {
    id: 15,
    title: "La Conferenza di Settore",
    description:
      "Hai l'opportunità di partecipare a una conferenza importante.",
    options: [
      {
        text: "Partecipi per apprendere nuove competenze.",
        effect: { Leadership: 10, "Problem Solving": 10 },
        xpGained: 80,
        nextScenarioId: 19,
        itemId: "conference_ticket",
      },
      {
        text: "Invii un membro del team per favorire la sua crescita.",
        effect: { Empatia: 15 },
        xpGained: 70,
        nextScenarioId: 20,
        itemId: "conference_ticket",
      },
    ],
  },
  {
    id: 16,
    title: "Opportunità Persa",
    description:
      "Il progetto internazionale non è andato bene a causa della mancanza di leadership.",
    options: [
      {
        text: "Riconosci l'errore e ti impegni a essere più presente in futuro.",
        effect: { Leadership: 10, Empatia: 5 },
        xpGained: 50,
        nextScenarioId: 15,
      },
      {
        text: "Incolpi il manager a cui hai delegato.",
        effect: { Empatia: -10, Comunicazione: -10 },
        xpGained: 20,
        nextScenarioId: 18,
      },
    ],
  },
  {
    id: 17,
    title: "Felicità del Team",
    description:
      "Il tuo team apprezza la tua decisione di restare e mostra maggiore motivazione.",
    options: [
      {
        text: "Continui a lavorare per sviluppare ulteriormente il team.",
        effect: { Empatia: 10, Comunicazione: 10 },
        xpGained: 80,
        nextScenarioId: 21,
        itemId: "team_photo",
      },
    ],
  },
  {
    id: 18,
    title: "Esito Negativo",
    description:
      "La situazione è degenerata e ricevi un richiamo formale.",
    options: [
      {
        text: "Decidi di seguire un corso di leadership per migliorare.",
        effect: { Leadership: 15 },
        xpGained: 80,
        nextScenarioId: 13, // Rimanda allo scenario di Auto-Miglioramento
        itemId: "leadership_course_certificate",
      },
      {
        text: "Ignori il richiamo e continui come sempre.",
        effect: { Empatia: -20, Comunicazione: -15 },
        xpGained: 10,
        nextScenarioId: null, // Fine negativa del gioco
      },
    ],
  },
  {
    id: 19,
    title: "Implementazione delle Nuove Competenze",
    description:
      "Applichi ciò che hai imparato alla conferenza nel tuo lavoro.",
    options: [
      {
        text: "Organizzi workshop per condividere le nuove conoscenze con il team.",
        effect: { Comunicazione: 15, Leadership: 10 },
        xpGained: 90,
        nextScenarioId: 21,
      },
    ],
  },
  {
    id: 20,
    title: "Crescita del Team",
    description:
      "Il membro del team ritorna dalla conferenza con nuove idee.",
    options: [
      {
        text: "Incoraggi l'implementazione delle sue idee.",
        effect: { Empatia: 10, Leadership: 10 },
        xpGained: 80,
        nextScenarioId: 21,
      },
      {
        text: "Decidi di non applicare le nuove idee per mantenere le vecchie pratiche.",
        effect: { Comunicazione: -10, Empatia: -5 },
        xpGained: 30,
        nextScenarioId: 18,
      },
    ],
  },
  {
    id: 21,
    title: "Risultati Positivi",
    description:
      "Il team ottiene ottimi risultati grazie alle nuove strategie.",
    options: [
      {
        text: "Proponi nuove sfide per continuare a crescere.",
        effect: { Leadership: 15, "Problem Solving": 10 },
        xpGained: 100,
        nextScenarioId: null,
        itemId: "performance_report",
      },
    ],
  },
  {
    id: 22,
    title: "Sfida all'Autorità",
    description:
      "A causa della tua scarsa leadership, un membro del team mette in discussione le tue decisioni pubblicamente.",
    options: [
      {
        text: "Ascolti le sue preoccupazioni e cerchi di trovare un compromesso.",
        effect: { Empatia: 10, Leadership: 5 },
        xpGained: 70,
        nextScenarioId: 23,
      },
      {
        text: "Ignori le sue obiezioni e imponi la tua decisione.",
        effect: { Leadership: -10, Comunicazione: -5 },
        xpGained: 30,
        nextScenarioId: 24,
      },
    ],
    requiredCompetency: {
      Leadership: { max: 40 },
    },
  },
  {
    id: 23,
    title: "Riconciliazione",
    description:
      "La tua disponibilità al dialogo ha migliorato l'atmosfera nel team.",
    options: [
      {
        text: "Organizzi un meeting per discutere nuove idee.",
        effect: { Comunicazione: 10, Leadership: 5 },
        xpGained: 80,
        nextScenarioId: 25,
      },
    ],
  },
  {
    id: 24,
    title: "Disaffezione del Team",
    description:
      "Il tuo comportamento autoritario ha causato malcontento nel team.",
    options: [
      {
        text: "Riconosci l'errore e chiedi scusa al team.",
        effect: { Empatia: 15 },
        xpGained: 60,
        nextScenarioId: 23,
      },
      {
        text: "Continui per la tua strada senza cambiare atteggiamento.",
        effect: { Leadership: -15 },
        xpGained: 20,
        nextScenarioId: 26,
      },
    ],
  },
  {
    id: 25,
    title: "Innovazione di Successo",
    description:
      "Le nuove idee discusse hanno portato a un progetto innovativo.",
    options: [
      {
        text: "Promuovi il progetto ai superiori, dando credito al team.",
        effect: { Comunicazione: 10, Leadership: 10 },
        xpGained: 100,
        nextScenarioId: 27,
      },
    ],
  },
  {
    id: 26,
    title: "Richiamo Formale",
    description:
      "La situazione è degenerata e i superiori ti hanno richiamato formalmente.",
    options: [
      {
        text: "Decidi di seguire un corso di leadership per migliorare.",
        effect: { Leadership: 15 },
        xpGained: 80,
        nextScenarioId: 13, // Rimanda allo scenario di Auto-Miglioramento
        itemId: "leadership_course_certificate",
      },
      {
        text: "Ignori il richiamo e continui come sempre.",
        effect: { Empatia: -20, Comunicazione: -15 },
        xpGained: 10,
        nextScenarioId: null, // Fine negativa del gioco
      },
    ],
  },
  {
    id: 27,
    title: "Riconoscimento Aziendale",
    description:
      "Il progetto innovativo ha ricevuto riconoscimenti a livello aziendale.",
    options: [
      {
        text: "Continui a incentivare la creatività all'interno del team.",
        effect: { Leadership: 15, Empatia: 10 },
        xpGained: 120,
        nextScenarioId: null, // Fine positiva del gioco
        itemId: "innovation_award",
      },
    ],
  },
  {
    id: 28,
    title: "Sovraccarico di Lavoro",
    description:
      "A causa della tua scarsa gestione del tempo, ti ritrovi sommerso di lavoro.",
    options: [
      {
        text: "Prendi un giorno libero per recuperare le energie.",
        effect: { "Gestione del Tempo": -5 },
        xpGained: 30,
        nextScenarioId: 29,
      },
      {
        text: "Continui a lavorare senza pause, rischiando il burnout.",
        effect: { "Gestione del Tempo": -15, Empatia: -5 },
        xpGained: 20,
        nextScenarioId: 30,
      },
    ],
    requiredCompetency: {
      "Gestione del Tempo": { max: 40 },
    },
  },
  {
    id: 29,
    title: "Recupero Energetico",
    description:
      "Dopo una pausa, ritorni al lavoro con nuove energie.",
    options: [
      {
        text: "Riorganizzi le tue priorità per gestire meglio il carico di lavoro.",
        effect: { "Gestione del Tempo": 10, "Problem Solving": 5 },
        xpGained: 70,
        nextScenarioId: 31,
        itemId: "time_management_guide",
      },
    ],
  },
  {
    id: 30,
    title: "Burnout",
    description:
      "Lo stress accumulato ti porta a un esaurimento professionale.",
    options: [
      {
        text: "Chiedi supporto al reparto risorse umane.",
        effect: { Empatia: 5 },
        xpGained: 50,
        nextScenarioId: 29,
      },
      {
        text: "Ignori i segnali e continui a lavorare.",
        effect: { "Gestione del Tempo": -20, Leadership: -10 },
        xpGained: 10,
        nextScenarioId: null, // Fine negativa del gioco
      },
    ],
  },
  {
    id: 31,
    title: "Miglioramento della Produttività",
    description:
      "Le nuove strategie di gestione del tempo hanno migliorato la tua produttività.",
    options: [
      {
        text: "Condividi le tue strategie con il team.",
        effect: { Comunicazione: 10, Leadership: 5 },
        xpGained: 80,
        nextScenarioId: 27,
      },
    ],
  },
  {
    id: 32,
    title: "Opportunità di Mentoring",
    description:
      "Grazie alle tue alte competenze, ti viene chiesto di diventare mentore per nuovi manager.",
    options: [
      {
        text: "Accetti l'incarico e condividi la tua esperienza.",
        effect: { Leadership: 10, Empatia: 10 },
        xpGained: 100,
        nextScenarioId: 33,
        itemId: "mentor_badge",
      },
      {
        text: "Rifiuti per concentrarti sui tuoi progetti.",
        effect: { Empatia: -5 },
        xpGained: 40,
        nextScenarioId: 34,
      },
    ],
    requiredCompetency: {
      Leadership: { min: 70 },
      Empatia: { min: 70 },
    },
  },
  {
    id: 33,
    title: "Successo come Mentore",
    description:
      "I nuovi manager traggono grande beneficio dalla tua guida.",
    options: [
      {
        text: "Continui il tuo percorso di crescita personale e professionale.",
        effect: { Leadership: 10, Comunicazione: 10 },
        xpGained: 120,
        nextScenarioId: null, // Fine positiva del gioco
      },
    ],
  },
  {
    id: 34,
    title: "Opportunità Mancata",
    description:
      "La decisione di non diventare mentore ha limitato la tua crescita personale.",
    options: [
      {
        text: "Riconsideri la proposta e decidi di accettare.",
        effect: { Empatia: 5 },
        xpGained: 60,
        nextScenarioId: 33,
      },
      {
        text: "Continui a focalizzarti solo sui tuoi obiettivi.",
        effect: { Leadership: -10 },
        xpGained: 20,
        nextScenarioId: null, // Fine neutra del gioco
      },
    ],
  },

  // Nuovi Scenari per Email Basate su Eventi (ID 35-39)

  // Scenario 35: Eventuale Email di Inizio Gioco (Opzionale)
  // Se desideri inviare un'email al momento dell'inizio del gioco, puoi definirla qui.
  // Altrimenti, puoi saltare questo scenario.
  {
    id: 35,
    title: "Benvenuto nel Tuo Nuovo Ruolo",
    description:
      "Hai appena iniziato il tuo ruolo di manager. Ecco alcune risorse per aiutarti a iniziare.",
    options: [
      {
        text: "Leggi le risorse fornite e pianifica i tuoi primi passi.",
        effect: { Comunicazione: 5, Leadership: 5 },
        xpGained: 20,
        nextScenarioId: 1, // Ritorna al primo scenario
      },
    ],
  },

  // Scenario 36: Risposta alla Email 'mission_completed'
  {
    id: 36,
    title: "Feedback sulla Missione",
    description:
      "Hai ricevuto un feedback dettagliato sulla missione appena completata. Come desideri procedere?",
    options: [
      {
        text: "Apprezzi il feedback e chiedi ulteriori dettagli per migliorare.",
        effect: { Comunicazione: 10, Leadership: 5 },
        xpGained: 50,
        nextScenarioId: 37,
      },
      {
        text: "Ringrazi per il feedback e continui senza ulteriori commenti.",
        effect: { Comunicazione: 5 },
        xpGained: 30,
        nextScenarioId: 38,
      },
      {
        text: "Ignori il feedback e continui come prima.",
        effect: { Comunicazione: -10, Leadership: -5 },
        xpGained: 10,
        nextScenarioId: 39,
      },
    ],
  },

  // Scenario 37: Miglioramento Continuo
  {
    id: 37,
    title: "Miglioramento Continuo",
    description:
      "Il tuo interesse a migliorare ha portato a nuove opportunità di sviluppo.",
    options: [
      {
        text: "Partecipi a un workshop di leadership avanzata.",
        effect: { Leadership: 15, Comunicazione: 10 },
        xpGained: 80,
        nextScenarioId: 40,
        itemId: "advanced_leadership_cert",
      },
      {
        text: "Inizi un programma di mentoring per il team.",
        effect: { Empatia: 15, Leadership: 5 },
        xpGained: 70,
        nextScenarioId: 41,
      },
    ],
  },

  // Scenario 38: Continui Senza Commenti
  {
    id: 38,
    title: "Proseguimento delle Attività",
    description:
      "Continui con le tue attività senza prendere ulteriori misure per migliorare.",
    options: [
      {
        text: "Mantieni lo stesso approccio e aspetti feedback futuri.",
        effect: { Comunicazione: 0, Leadership: 0 },
        xpGained: 20,
        nextScenarioId: 39,
      },
    ],
  },

  // Scenario 39: Ignori il Feedback
  {
    id: 39,
    title: "Ignorare il Feedback",
    description:
      "Hai deciso di ignorare il feedback ricevuto. Questo può influenzare la percezione del tuo team e dei superiori.",
    options: [
      {
        text: "Continuare a lavorare con lo stesso approccio.",
        effect: { Leadership: -5, Comunicazione: -5 },
        xpGained: 10,
        nextScenarioId: 42, // Fine negativa del gioco
      },
      {
        text: "Riconsideri il tuo approccio e decidi di migliorare.",
        effect: { Comunicazione: 10, Leadership: 5 },
        xpGained: 40,
        nextScenarioId: 37,
      },
    ],
  },

  // Scenario 40: Workshop di Leadership Avanzata
  {
    id: 40,
    title: "Workshop di Leadership Avanzata",
    description:
      "Hai partecipato a un workshop che ha migliorato significativamente le tue competenze di leadership.",
    options: [
      {
        text: "Applichi le nuove tecniche nel tuo team.",
        effect: { Leadership: 15, Comunicazione: 10 },
        xpGained: 90,
        nextScenarioId: 43,
        itemId: "leadership_toolkit",
      },
      {
        text: "Condividi le conoscenze con il tuo team attraverso sessioni formative.",
        effect: { Comunicazione: 15, Leadership: 5 },
        xpGained: 80,
        nextScenarioId: 44,
      },
    ],
  },

  // Scenario 41: Programma di Mentoring
  {
    id: 41,
    title: "Programma di Mentoring",
    description:
      "Hai avviato un programma di mentoring che ha rafforzato le competenze del tuo team.",
    options: [
      {
        text: "Continui a espandere il programma per includere più membri del team.",
        effect: { Empatia: 10, Leadership: 10 },
        xpGained: 100,
        nextScenarioId: 45,
        itemId: "mentoring_cert",
      },
      {
        text: "Valuti l'efficacia del programma e apporti modifiche.",
        effect: { Comunicazione: 10, 'Problem Solving': 5 },
        xpGained: 70,
        nextScenarioId: 43,
      },
    ],
  },

  // Scenario 42: Fine Negativa del Gioco
  {
    id: 42,
    title: "Richiamo Formale",
    description:
      "Ignorando il feedback, la tua gestione ha portato a un grave calo di produttività e morale nel team.",
    options: [
      {
        text: "Decidi di lasciare il ruolo di manager.",
        effect: {},
        xpGained: 0,
        nextScenarioId: null, // Fine negativa del gioco
      },
      {
        text: "Chiedi aiuto a un consulente per migliorare la tua leadership.",
        effect: { Leadership: 10, Comunicazione: 10 },
        xpGained: 50,
        nextScenarioId: 37,
      },
    ],
  },

  // Scenario 43: Implementazione delle Nuove Tecniche
  {
    id: 43,
    title: "Implementazione delle Nuove Tecniche",
    description:
      "Le nuove tecniche di leadership hanno migliorato l'efficienza del tuo team.",
    options: [
      {
        text: "Continui a sviluppare nuove strategie per ulteriori miglioramenti.",
        effect: { Leadership: 10, Comunicazione: 10 },
        xpGained: 100,
        nextScenarioId: 46,
        itemId: "strategic_plan",
      },
      {
        text: "Mantieni le tecniche attuali e aspetti ulteriori feedback.",
        effect: { Comunicazione: 5, Leadership: 5 },
        xpGained: 70,
        nextScenarioId: 44,
      },
    ],
  },

  // Scenario 44: Condivisione delle Conoscenze
  {
    id: 44,
    title: "Condivisione delle Conoscenze",
    description:
      "Hai organizzato sessioni formative per condividere le tue nuove competenze con il team.",
    options: [
      {
        text: "Il team applica con successo le nuove competenze.",
        effect: { Leadership: 15, Comunicazione: 10 },
        xpGained: 90,
        nextScenarioId: 45,
        itemId: "team_training_certificate",
      },
      {
        text: "Alcuni membri del team trovano difficile adattarsi alle nuove tecniche.",
        effect: { Empatia: -5, Comunicazione: -5 },
        xpGained: 40,
        nextScenarioId: 46,
      },
    ],
  },

  // Scenario 45: Espansione del Programma di Mentoring
  {
    id: 45,
    title: "Espansione del Programma di Mentoring",
    description:
      "Il tuo programma di mentoring ha avuto un impatto positivo su molti membri del team.",
    options: [
      {
        text: "Ricevi riconoscimenti aziendali per il tuo impegno.",
        effect: { Leadership: 20, Empatia: 15 },
        xpGained: 120,
        nextScenarioId: null, // Fine positiva del gioco
        itemId: "employee_of_the_month",
      },
      {
        text: "Decidi di mantenere il programma come è, senza ulteriori espansioni.",
        effect: { Comunicazione: 5 },
        xpGained: 60,
        nextScenarioId: 46,
      },
    ],
  },

  // Scenario 46: Affrontare le Difficoltà
  {
    id: 46,
    title: "Affrontare le Difficoltà",
    description:
      "Stai affrontando alcune difficoltà nell'implementazione delle nuove strategie.",
    options: [
      {
        text: "Richiedi feedback continuo dal team per migliorare le strategie.",
        effect: { Comunicazione: 10, Leadership: 10 },
        xpGained: 80,
        nextScenarioId: 43,
      },
      {
        text: "Decidi di fermarti e riconsiderare completamente il tuo approccio.",
        effect: { Leadership: -10, Comunicazione: -10 },
        xpGained: 30,
        nextScenarioId: 42, // Fine negativa del gioco
      },
    ],
  },

  // Scenario 27-34 (ripresa degli scenari esistenti)
  // ... (Assicurati che tutti gli scenari fino a ID 34 siano inclusi come forniti dall'utente)

  // Scenario 35: (Opzionale) Email di Inizio Gioco
  // Se non desideri inviare un'email all'inizio, puoi rimuovere questo scenario
  // Altrimenti, puoi utilizzarlo come punto di partenza
  {
    id: 35,
    title: "Benvenuto nel Tuo Nuovo Ruolo",
    description:
      "Hai appena iniziato il tuo ruolo di manager. Ecco alcune risorse per aiutarti a iniziare.",
    options: [
      {
        text: "Leggi le risorse fornite e pianifica i tuoi primi passi.",
        effect: { Comunicazione: 5, Leadership: 5 },
        xpGained: 20,
        nextScenarioId: 1, // Ritorna al primo scenario
      },
    ],
  },

  // Scenario 39: Risposta alla Email 'level_up'
  {
    id: 39,
    title: "Aggiornamento Livello",
    description:
      "Congratulazioni per il tuo avanzamento di livello! Ecco cosa puoi fare ora.",
    options: [
      {
        text: "Partecipa a un corso avanzato di leadership.",
        effect: { Leadership: 15, Comunicazione: 10 },
        xpGained: 100,
        nextScenarioId: 40,
        itemId: "advanced_leadership_cert",
      },
      {
        text: "Inizia a guidare un progetto più grande.",
        effect: { Leadership: 10, "Problem Solving": 10 },
        xpGained: 90,
        nextScenarioId: 41,
      },
    ],
  },

  // Scenario 40: Workshop di Leadership Avanzata
  {
    id: 40,
    title: "Workshop di Leadership Avanzata",
    description:
      "Hai partecipato a un workshop che ha migliorato significativamente le tue competenze di leadership.",
    options: [
      {
        text: "Applichi le nuove tecniche nel tuo team.",
        effect: { Leadership: 15, Comunicazione: 10 },
        xpGained: 90,
        nextScenarioId: 43,
        itemId: "leadership_toolkit",
      },
      {
        text: "Condividi le conoscenze con il tuo team attraverso sessioni formative.",
        effect: { Comunicazione: 15, Leadership: 5 },
        xpGained: 80,
        nextScenarioId: 44,
      },
    ],
  },

  // Scenario 41: Guida di un Progetto Più Grande
  {
    id: 41,
    title: "Guida di un Progetto Più Grande",
    description:
      "Hai assunto la leadership di un progetto più grande e complesso.",
    options: [
      {
        text: "Implementi strategie avanzate per gestire il progetto.",
        effect: { Leadership: 15, "Problem Solving": 15 },
        xpGained: 110,
        nextScenarioId: 45,
        itemId: "project_leader_badge",
      },
      {
        text: "Delegare ulteriormente per gestire il carico di lavoro.",
        effect: { Empatia: 10, Leadership: 5 },
        xpGained: 70,
        nextScenarioId: 46,
      },
    ],
  },

  // Scenario 43: Implementazione delle Nuove Tecniche
  {
    id: 43,
    title: "Implementazione delle Nuove Tecniche",
    description:
      "Le nuove tecniche di leadership hanno migliorato l'efficienza del tuo team.",
    options: [
      {
        text: "Continui a sviluppare nuove strategie per ulteriori miglioramenti.",
        effect: { Leadership: 10, Comunicazione: 10 },
        xpGained: 100,
        nextScenarioId: 46,
        itemId: "strategic_plan",
      },
      {
        text: "Mantieni le tecniche attuali e aspetti ulteriori feedback.",
        effect: { Comunicazione: 5, Leadership: 5 },
        xpGained: 70,
        nextScenarioId: 44,
      },
    ],
  },

  // Scenario 44: Condivisione delle Conoscenze
  {
    id: 44,
    title: "Condivisione delle Conoscenze",
    description:
      "Hai organizzato sessioni formative per condividere le tue nuove competenze con il team.",
    options: [
      {
        text: "Il team applica con successo le nuove competenze.",
        effect: { Leadership: 15, Comunicazione: 10 },
        xpGained: 90,
        nextScenarioId: 45,
        itemId: "team_training_certificate",
      },
      {
        text: "Alcuni membri del team trovano difficile adattarsi alle nuove tecniche.",
        effect: { Empatia: -5, Comunicazione: -5 },
        xpGained: 40,
        nextScenarioId: 46,
      },
    ],
  },

  // Scenario 45: Espansione del Programma di Mentoring
  {
    id: 45,
    title: "Espansione del Programma di Mentoring",
    description:
      "Il tuo programma di mentoring ha avuto un impatto positivo su molti membri del team.",
    options: [
      {
        text: "Ricevi riconoscimenti aziendali per il tuo impegno.",
        effect: { Leadership: 20, Empatia: 15 },
        xpGained: 120,
        nextScenarioId: null, // Fine positiva del gioco
        itemId: "employee_of_the_month",
      },
      {
        text: "Decidi di mantenere il programma come è, senza ulteriori espansioni.",
        effect: { Comunicazione: 5 },
        xpGained: 60,
        nextScenarioId: 46,
      },
    ],
  },

  // Scenario 46: Affrontare le Difficoltà
  {
    id: 46,
    title: "Affrontare le Difficoltà",
    description:
      "Stai affrontando alcune difficoltà nell'implementazione delle nuove strategie.",
    options: [
      {
        text: "Richiedi feedback continuo dal team per migliorare le strategie.",
        effect: { Comunicazione: 10, Leadership: 10 },
        xpGained: 80,
        nextScenarioId: 43,
      },
      {
        text: "Decidi di fermarti e riconsiderare completamente il tuo approccio.",
        effect: { Leadership: -10, Comunicazione: -10 },
        xpGained: 30,
        nextScenarioId: 42, // Fine negativa del gioco
      },
    ],
  },
  {
    id: 47,
    title: 'Dilemma Etico durante un Audit',
    description:
      'Sei il responsabile di un team di audit presso un importante cliente. Durante l\'analisi dei documenti finanziari, uno dei tuoi collaboratori ti segnala delle irregolarità che potrebbero indicare frode. Il cliente è uno dei più grandi per la tua azienda, e una segnalazione potrebbe compromettere la relazione commerciale. Il direttore finanziario del cliente ti invita a "sorvolare" sulla questione, promettendo future opportunità di business.',
    options: [
      {
        text: 'Segnalare immediatamente le irregolarità al tuo superiore e procedere secondo il protocollo aziendale.',
        effect: { 'Integrità': 10 },
        xpGained: 50,
        nextScenarioId: 48,
        reputationChange: { 'cliente': -20, 'azienda': 10 },
      },
      {
        text: 'Discutere privatamente con il team interno del cliente per cercare di risolvere la questione senza segnalazioni ufficiali.',
        effect: { 'Problem Solving': 5, 'Integrità': -5 },
        xpGained: 30,
        nextScenarioId: 49,
        reputationChange: { 'cliente': -10 },
      },
      {
        text: 'Accettare la richiesta del direttore finanziario e ignorare le irregolarità.',
        effect: { 'Integrità': -15 },
        xpGained: 10,
        nextScenarioId: 50,
        reputationChange: { 'cliente': 10, 'azienda': -20 },
      },
    ],
  },

  // Scenario 48: Conseguenze della Segnalazione
  {
    id: 48,
    title: 'Conseguenze della Segnalazione',
    description:
      'La tua azienda apprezza la tua integrità e prende in carico la situazione. Tuttavia, il cliente è furioso e minaccia di interrompere la collaborazione. Il tuo superiore ti chiede di partecipare a una riunione con il cliente per cercare di salvare la relazione.',
    options: [
      {
        text: 'Prepararsi accuratamente per la riunione, raccogliendo tutte le evidenze e proponendo soluzioni.',
        effect: { 'Comunicazione': 5, 'Leadership': 5 },
        xpGained: 40,
        nextScenarioId: 51,
        reputationChange: { 'cliente': -5 },
      },
      {
        text: 'Affidare la gestione della riunione al tuo superiore, ritenendo di aver già fatto abbastanza.',
        effect: { 'Leadership': -5 },
        xpGained: 20,
        nextScenarioId: 52,
        reputationChange: { 'azienda': -5 },
      },
    ],
  },

  // Scenario 49: Tentativo di Risoluzione Interna
  {
    id: 49,
    title: 'Tentativo di Risoluzione Interna',
    description:
      'Nel discutere con il team interno del cliente, incontri resistenza e ostilità. Alcuni membri del team minacciano di segnalare la tua interferenza ai loro superiori. La situazione diventa tesa e potrebbe sfuggire al controllo.',
    options: [
      {
        text: 'Insistere sulla risoluzione interna, cercando di convincerli dell\'importanza della questione.',
        effect: { 'Comunicazione': -5, 'Problem Solving': -5 },
        xpGained: 20,
        nextScenarioId: 53,
        reputationChange: { 'cliente': -15 },
      },
      {
        text: 'Decidere di informare il tuo superiore della situazione, ammettendo di aver tentato una risoluzione informale.',
        effect: { 'Integrità': 5 },
        xpGained: 30,
        nextScenarioId: 48, // Torna allo scenario di segnalazione ufficiale
        reputationChange: { 'azienda': -5 },
      },
    ],
  },

  // Scenario 50: Scoperta della Frode
  {
    id: 50,
    title: 'Scoperta della Frode',
    description:
      'Mesi dopo, emergono notizie pubbliche sulla frode del cliente. La tua azienda viene criticata per non aver rilevato le irregolarità. Il tuo coinvolgimento viene messo in discussione, e potresti affrontare conseguenze legali e professionali.',
    options: [
      {
        text: 'Ammettere le proprie responsabilità e collaborare con le autorità.',
        effect: { 'Integrità': -10, 'Leadership': -10 },
        xpGained: 0,
        nextScenarioId: null, // Fine del gioco per cattiva condotta
        reputationChange: { 'azienda': -30 },
      },
      {
        text: 'Negare ogni coinvolgimento e cercare di incolpare altri membri del team.',
        effect: { 'Integrità': -20 },
        xpGained: 0,
        nextScenarioId: null,
        reputationChange: { 'azienda': -50 },
      },
    ],
  },

  // Scenario 51: Riunione con il Cliente
  {
    id: 51,
    title: 'Riunione con il Cliente',
    description:
      'Durante la riunione, presenti le tue evidenze in modo professionale. Il cliente, sebbene inizialmente ostile, riconosce la gravità della situazione. Si apre la possibilità di collaborare per risolvere il problema in modo discreto.',
    options: [
      {
        text: 'Proporre un piano d\'azione congiunto per correggere le irregolarità.',
        effect: { 'Leadership': 10, 'Problem Solving': 5 },
        xpGained: 50,
        nextScenarioId: 54,
        reputationChange: { 'cliente': 10 },
      },
      {
        text: 'Suggerire di terminare il rapporto professionale per evitare ulteriori complicazioni.',
        effect: { 'Integrità': 5 },
        xpGained: 20,
        nextScenarioId: 55,
        reputationChange: { 'cliente': -10 },
      },
    ],
  },

  // Scenario 52: Esclusione dal Progetto
  {
    id: 52,
    title: 'Esclusione dal Progetto',
    description:
      'Il tuo superiore prende in carico la situazione senza coinvolgerti ulteriormente. La direzione percepisce una mancanza di iniziativa da parte tua. Vieni escluso da futuri progetti importanti.',
    options: [
      {
        text: 'Accettare la decisione e cercare di migliorare le proprie competenze.',
        effect: { 'Leadership': -5, 'Comunicazione': -5 },
        xpGained: 10,
        nextScenarioId: 56,
        reputationChange: { 'azienda': -10 },
      },
      {
        text: 'Richiedere un colloquio con le risorse umane per discutere della situazione.',
        effect: { 'Comunicazione': 5 },
        xpGained: 20,
        nextScenarioId: 57,
        reputationChange: { 'azienda': 0 },
      },
    ],
  },

  // Scenario 53: Escalation della Tensione
  {
    id: 53,
    title: 'Escalation della Tensione',
    description:
      'Le tue insistenze peggiorano la situazione. Il cliente presenta una lamentela formale contro di te. La tua azienda è costretta a intervenire per gestire la crisi.',
    options: [
      {
        text: 'Ammettere gli errori e chiedere scusa al cliente.',
        effect: { 'Integrità': 5 },
        xpGained: 20,
        nextScenarioId: 58,
        reputationChange: { 'cliente': -10, 'azienda': -10 },
      },
      {
        text: 'Difendere le proprie azioni, sostenendo di aver agito nel migliore interesse.',
        effect: { 'Leadership': -5 },
        xpGained: 10,
        nextScenarioId: 59,
        reputationChange: { 'cliente': -20, 'azienda': -15 },
      },
    ],
  },

  // Scenario 54: Collaborazione per la Soluzione
  {
    id: 54,
    title: 'Collaborazione per la Soluzione',
    description:
      'Grazie alla tua iniziativa, il cliente corregge le irregolarità e rafforza i controlli interni. La relazione commerciale esce rafforzata, e la tua azienda ti riconosce il merito del successo.',
    options: [
      {
        text: 'Accettare un nuovo incarico di maggiore responsabilità.',
        effect: { 'Leadership': 10 },
        xpGained: 60,
        nextScenarioId: 60,
        reputationChange: { 'azienda': 20 },
      },
      {
        text: 'Richiedere una promozione o un aumento di stipendio.',
        effect: { 'Comunicazione': 5 },
        xpGained: 40,
        nextScenarioId: 61,
        reputationChange: { 'azienda': 10 },
      },
    ],
  },

  // Scenario 55: Terminazione del Rapporto con il Cliente
  {
    id: 55,
    title: 'Terminazione del Rapporto con il Cliente',
    description:
      'La tua azienda accetta di interrompere la collaborazione con il cliente. Sebbene abbia perso un importante contratto, la direzione apprezza la tua integrità.',
    options: [
      {
        text: 'Proporre strategie per attrarre nuovi clienti e compensare la perdita.',
        effect: { 'Leadership': 5, 'Problem Solving': 5 },
        xpGained: 50,
        nextScenarioId: 62,
        reputationChange: { 'azienda': 15 },
      },
      {
        text: 'Concentrarsi sui progetti esistenti e migliorare le operazioni interne.',
        effect: { 'Gestione del Tempo': 5 },
        xpGained: 30,
        nextScenarioId: 63,
        reputationChange: { 'azienda': 5 },
      },
    ],
  },

  // Scenario 56: Percorso di Miglioramento
  {
    id: 56,
    title: 'Percorso di Miglioramento',
    description:
      'Decidi di lavorare sulle tue competenze partecipando a corsi di formazione. Nel tempo, recuperi la fiducia della direzione e vieni reintegrato in progetti importanti.',
    options: [
      {
        text: 'Specializzarsi in un settore chiave per l\'azienda.',
        effect: { 'Competenza Tecnica': 10 },
        xpGained: 40,
        nextScenarioId: 64,
        reputationChange: { 'azienda': 10 },
      },
      {
        text: 'Assumere un ruolo di mentoring per i nuovi assunti.',
        effect: { 'Leadership': 5, 'Empatia': 5 },
        xpGained: 40,
        nextScenarioId: 65,
        reputationChange: { 'azienda': 10 },
      },
    ],
  },

  // Scenario 57: Discussione con le Risorse Umane
  {
    id: 57,
    title: 'Discussione con le Risorse Umane',
    description:
      'Durante il colloquio, esprimi le tue preoccupazioni riguardo alla gestione del progetto e alle decisioni prese. Le risorse umane apprezzano la tua franchezza e ti offrono supporto.',
    options: [
      {
        text: 'Accettare il supporto e lavorare per migliorare la comunicazione con il team.',
        effect: { 'Comunicazione': 5 },
        xpGained: 30,
        nextScenarioId: 66,
        reputationChange: { 'azienda': 5 },
      },
      {
        text: 'Rifiutare il supporto, ritenendo di poter gestire la situazione da solo.',
        effect: { 'Empatia': -5 },
        xpGained: 10,
        nextScenarioId: 67,
        reputationChange: { 'azienda': -5 },
      },
    ],
  },

  // Scenario 58: Ammissione di Colpa
  {
    id: 58,
    title: 'Ammissione di Colpa',
    description:
      'La tua ammissione allevia la tensione e apre la porta a una possibile riconciliazione. Tuttavia, la direzione rimane cauta riguardo al tuo giudizio professionale.',
    options: [
      {
        text: 'Chiedere una seconda possibilità e dimostrare il tuo valore.',
        effect: { 'Integrità': 5, 'Leadership': 5 },
        xpGained: 30,
        nextScenarioId: 68,
        reputationChange: { 'azienda': 5 },
      },
      {
        text: 'Accettare una posizione di minore responsabilità per ricostruire la fiducia.',
        effect: { 'Gestione del Tempo': 5 },
        xpGained: 20,
        nextScenarioId: 69,
        reputationChange: { 'azienda': 0 },
      },
    ],
  },

  // Scenario 59: Conseguenze Legali
  {
    id: 59,
    title: 'Conseguenze Legali',
    description:
      'La situazione degenera, e sia il cliente che la tua azienda avviano azioni legali contro di te. La tua carriera subisce un duro colpo, e la tua reputazione è compromessa.',
    options: [
      {
        text: 'Assumere un avvocato e difendersi in tribunale.',
        effect: { 'Integrità': -10 },
        xpGained: 0,
        nextScenarioId: null,
        reputationChange: { 'azienda': -30, 'cliente': -30 },
      },
      {
        text: 'Tentare una mediazione extragiudiziale.',
        effect: { 'Problem Solving': -5 },
        xpGained: 0,
        nextScenarioId: null,
        reputationChange: { 'azienda': -20, 'cliente': -20 },
      },
    ],
  },

  // Scenario 60: Nuovo Incarico di Responsabilità
  {
    id: 60,
    title: 'Nuovo Incarico di Responsabilità',
    description:
      'La tua azienda ti affida un progetto strategico internazionale. Hai l\'opportunità di guidare un team diversificato e di avere un impatto significativo sulla crescita aziendale.',
    options: [
      {
        text: 'Investire tempo nella formazione del team per assicurare il successo del progetto.',
        effect: { 'Leadership': 10, 'Empatia': 5 },
        xpGained: 60,
        nextScenarioId: 70,
        reputationChange: { 'azienda': 15 },
      },
      {
        text: 'Concentrarsi sugli obiettivi e spingere il team a raggiungerli rapidamente.',
        effect: { 'Gestione del Tempo': 10, 'Empatia': -5 },
        xpGained: 50,
        nextScenarioId: 71,
        reputationChange: { 'azienda': 10 },
      },
    ],
  },

  // Scenario 61: Richiesta di Promozione
  {
    id: 61,
    title: 'Richiesta di Promozione',
    description:
      'La direzione apprezza la tua ambizione ma sottolinea che le promozioni sono basate su risultati a lungo termine. Ti viene chiesto di dimostrare ulteriormente le tue capacità.',
    options: [
      {
        text: 'Accettare la sfida e impegnarsi al massimo nei prossimi progetti.',
        effect: { 'Leadership': 5, 'Comunicazione': 5 },
        xpGained: 40,
        nextScenarioId: 72,
        reputationChange: { 'azienda': 10 },
      },
      {
        text: 'Esprimere insoddisfazione e considerare opportunità in altre aziende.',
        effect: { 'Integrità': -5 },
        xpGained: 20,
        nextScenarioId: 73,
        reputationChange: { 'azienda': -10 },
      },
    ],
  },

  // Scenario 62: Strategie per Nuovi Clienti
  {
    id: 62,
    title: 'Strategie per Nuovi Clienti',
    description:
      'Le tue proposte vengono accolte positivamente, e vieni incaricato di guidare l\'iniziativa di sviluppo commerciale. Questo ruolo richiede competenze avanzate di networking e negoziazione.',
    options: [
      {
        text: 'Creare partnership strategiche con altre aziende del settore.',
        effect: { 'Comunicazione': 10, 'Leadership': 5 },
        xpGained: 50,
        nextScenarioId: 74,
        reputationChange: { 'azienda': 15 },
      },
      {
        text: 'Organizzare eventi e conferenze per aumentare la visibilità dell\'azienda.',
        effect: { 'Comunicazione': 5, 'Gestione del Tempo': 5 },
        xpGained: 40,
        nextScenarioId: 75,
        reputationChange: { 'azienda': 10 },
      },
    ],
  },

  // Scenario 63: Miglioramento delle Operazioni Interne
  {
    id: 63,
    title: 'Miglioramento delle Operazioni Interne',
    description:
      'Concentrandoti sulle operazioni interne, identifichi inefficienze che, se corrette, potrebbero migliorare la produttività aziendale.',
    options: [
      {
        text: 'Implementare nuove tecnologie per automatizzare processi ripetitivi.',
        effect: { 'Competenza Tecnica': 5, 'Problem Solving': 5 },
        xpGained: 40,
        nextScenarioId: 76,
        reputationChange: { 'azienda': 10 },
      },
      {
        text: 'Formare il personale sulle best practices operative.',
        effect: { 'Leadership': 5, 'Empatia': 5 },
        xpGained: 40,
        nextScenarioId: 77,
        reputationChange: { 'azienda': 10 },
      },
    ],
  },

  // Scenario 64: Specializzazione Settoriale
  {
    id: 64,
    title: 'Specializzazione Settoriale',
    description:
      'Diventi un esperto riconosciuto nel settore scelto. Questo ti apre opportunità per partecipare a conferenze e pubblicare articoli specialistici.',
    options: [
      {
        text: 'Accettare inviti come relatore in eventi internazionali.',
        effect: { 'Comunicazione': 10 },
        xpGained: 50,
        nextScenarioId: 78,
        reputationChange: { 'azienda': 15 },
      },
      {
        text: 'Concentrare gli sforzi sulla ricerca e sviluppo interni.',
        effect: { 'Competenza Tecnica': 10 },
        xpGained: 50,
        nextScenarioId: 79,
        reputationChange: { 'azienda': 10 },
      },
    ],
  },

  // Scenario 65: Ruolo di Mentoring
  {
    id: 65,
    title: 'Ruolo di Mentoring',
    description:
      'Come mentore, aiuti nuovi talenti a crescere all\'interno dell\'azienda. Questo migliora l\'ambiente di lavoro e aumenta la fidelizzazione del personale.',
    options: [
      {
        text: 'Creare un programma di mentoring strutturato.',
        effect: { 'Leadership': 5, 'Empatia': 5 },
        xpGained: 40,
        nextScenarioId: 80,
        reputationChange: { 'azienda': 15 },
      },
      {
        text: 'Fornire supporto informale su base individuale.',
        effect: { 'Empatia': 10 },
        xpGained: 30,
        nextScenarioId: 81,
        reputationChange: { 'azienda': 10 },
      },
    ],
  },

  // Scenario 66: Migliorare la Comunicazione
  {
    id: 66,
    title: 'Migliorare la Comunicazione',
    description:
      'Lavori attivamente per migliorare la comunicazione all\'interno del team. Questo porta a una maggiore efficienza e a un ambiente di lavoro più positivo.',
    options: [
      {
        text: 'Implementare strumenti di collaborazione digitale.',
        effect: { 'Competenza Tecnica': 5, 'Comunicazione': 5 },
        xpGained: 40,
        nextScenarioId: 82,
        reputationChange: { 'azienda': 10 },
      },
      {
        text: 'Organizzare incontri settimanali per allineare gli obiettivi.',
        effect: { 'Gestione del Tempo': -5, 'Leadership': 5 },
        xpGained: 30,
        nextScenarioId: 83,
        reputationChange: { 'azienda': 5 },
      },
    ],
  },

  // Scenario 67: Autonomia nella Gestione
  {
    id: 67,
    title: 'Autonomia nella Gestione',
    description:
      'Decidi di gestire le sfide da solo. Questo ti porta a prendere decisioni difficili senza consultare il team o i superiori, con risultati misti.',
    options: [
      {
        text: 'Continuare su questa strada, confidando nelle proprie capacità.',
        effect: { 'Leadership': -5 },
        xpGained: 20,
        nextScenarioId: 84,
        reputationChange: { 'azienda': -10 },
      },
      {
        text: 'Riconoscere la necessità di collaborare e cambiare approccio.',
        effect: { 'Empatia': 5 },
        xpGained: 30,
        nextScenarioId: 85,
        reputationChange: { 'azienda': 5 },
      },
    ],
  },

  // Scenario 68: Seconda Possibilità
  {
    id: 68,
    title: 'Seconda Possibilità',
    description:
      'La direzione ti assegna un nuovo progetto per dimostrare le tue capacità. Questa è la tua occasione per riscattarti.',
    options: [
      {
        text: 'Dedicare tutto te stesso al progetto, coinvolgendo attivamente il team.',
        effect: { 'Leadership': 10, 'Empatia': 5 },
        xpGained: 50,
        nextScenarioId: 86,
        reputationChange: { 'azienda': 15 },
      },
      {
        text: 'Lavorare in modo indipendente per mostrare la tua competenza tecnica.',
        effect: { 'Competenza Tecnica': 10 },
        xpGained: 40,
        nextScenarioId: 87,
        reputationChange: { 'azienda': 5 },
      },
    ],
  },

  // Scenario 69: Ruolo di Supporto
  {
    id: 69,
    title: 'Ruolo di Supporto',
    description:
      'Nel tuo nuovo ruolo, supporti i progetti senza essere in prima linea. Questo ti permette di apprendere e migliorare senza la pressione delle responsabilità maggiori.',
    options: [
      {
        text: 'Utilizzare questo tempo per sviluppare nuove competenze.',
        effect: { 'Competenza Tecnica': 5, 'Problem Solving': 5 },
        xpGained: 30,
        nextScenarioId: 88,
        reputationChange: { 'azienda': 5 },
      },
      {
        text: 'Cercare opportunità per tornare a ruoli di leadership.',
        effect: { 'Leadership': 5 },
        xpGained: 30,
        nextScenarioId: 89,
        reputationChange: { 'azienda': 10 },
      },
    ],
  },

  // Scenario 27-34 (ripresa degli scenari esistenti)
  // ... (Assicurati che tutti gli scenari fino a ID 34 siano inclusi come forniti dall'utente)
  // Nota: Puoi copiare e incollare i tuoi scenari esistenti qui fino a ID 34

  // Esempio di continuazione (Assicurati di includere tutti gli scenari fino a ID 34)
  // ...

];
