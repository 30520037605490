// src/components/gamification/LeadershipSimulator/data/missions.js

const missions = [
    {
      id: 1,
      title: 'Inizia il Progetto X',
      description: 'Devi avviare il progetto X assegnando compiti ai membri del team.',
      objectives: [
        {
          id: 1,
          description: 'Assegna almeno un compito a ogni membro del team.',
          isCompleted: false,
        },
      ],
      rewards: {
        xpGained: 100,
        items: ['efficiency_medal'],
        competencies: { Leadership: 10 },
      },
      isActive: true,
    },
    // Aggiungi altre missioni qui
  ];
  
  export default missions;
  