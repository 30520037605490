// components/PerformanceList.js
import React from 'react';
import { List, Typography, Avatar, Progress } from 'antd'; // Importa i componenti di Ant Design
import styled from 'styled-components';
import { FaMedal } from 'react-icons/fa';

// Container per l'intera sezione delle performance, con bordo arrotondato e ombra
const PerformanceContainer = styled.div`
  margin-bottom: 20px;  /* Mantieni il margine inferiore per distanziare i box */
  padding: 10px 20px;   /* Riduci il padding superiore (10px) */
  border-radius: 8px;
  border: 1px solid #e7e5e4;
`;

const Title = styled(Typography.Title)`
  margin-top: 0;  /* Rimuovi il margine superiore del titolo */
`;


// Stile per ogni elemento della lista, con allineamento al centro
const StyledListItem = styled(List.Item)`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

// Stile per l'avatar contenente l'icona
const StyledAvatar = styled(Avatar)`
  background-color: ${props => (props.good ? '#ffcc00' : '#ff3b30')}; /* Oro per best performance, rosso per worst */
  margin-right: 10px;
`;

const ChartContainer = styled.div`
  width: 50px;
  height: 50px;
  margin-left: auto; /* Spinge il grafico circolare all'estrema destra */
`;

function PerformanceList({ title, data, good }) {
  return (
    <PerformanceContainer>
<Typography.Title level={4} style={{ marginTop: '10px' }}>{title}</Typography.Title>
<List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => (
          <StyledListItem>
            <StyledAvatar good={good}>
              <FaMedal color="white" />
            </StyledAvatar>
            <List.Item.Meta
              title={item.course}
              description={`Partecipanti: ${item.participants}`}
            />
            <ChartContainer>
              <Progress
                type="circle"
                percent={item.rating}
                format={percent => `${percent}%`}
                width={50}
                strokeColor={good ? '#34c759' : '#ff3b30'} // Verde per good, rosso per worst
              />
            </ChartContainer>
          </StyledListItem>
        )}
      />
    </PerformanceContainer>
  );
}

export default PerformanceList;
