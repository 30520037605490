// src/components/gamification/LeadershipSimulator/data/relationships.js

const relationships = [
  {
      id: 1,
      name: 'Anna Bianchi',
      role: 'Direttore', // Aggiornato per allinearsi con le email
      icon: '👩‍💼',
      reputation: 50, // Scala da 0 a 100
  },
  {
      id: 2,
      name: 'Marco Rossi',
      role: 'Manager', // Aggiornato per allinearsi con le email
      icon: '👨‍💼',
      reputation: 50,
  },
  { 
      id: 'azienda', 
      name: 'La Tua Azienda', 
      reputation: 50, 
      icon: '🏢' 
  },
  { 
      id: 'cliente', 
      name: 'Cliente Importante', 
      reputation: 50, 
      icon: '🤝' 
  },
  {
      id: 3,
      name: 'Direttore Marketing',
      role: 'Direttore', // Nuovo personaggio aggiunto
      icon: '📊',
      reputation: 50,
  },
  {
      id: 4,
      name: 'Team Leader IT',
      role: 'Team Leader', // Nuovo personaggio aggiunto
      icon: '💻',
      reputation: 50,
  },
  {
      id: 5,
      name: 'Responsabile Sicurezza',
      role: 'Responsabile', // Nuovo personaggio aggiunto
      icon: '🛡️',
      reputation: 50,
  },
  {
      id: 6,
      name: 'CEO',
      role: 'CEO', // Nuovo personaggio aggiunto
      icon: '👔',
      reputation: 50,
  },
  // Aggiungi altri personaggi qui se necessario
];

export default relationships;
