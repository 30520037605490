// EventModal.js
import React from 'react';
import { Modal, Button, Typography } from 'antd';

const EventModal = ({ currentEvent, handleEventChoice }) => {
  return (
    <Modal
      visible={true}
      title={currentEvent.title}
      footer={null}
      closable={false}
    >
      <Typography.Paragraph>{currentEvent.description}</Typography.Paragraph>
      {currentEvent.options.map((option, index) => (
        <Button
          key={index}
          onClick={() => handleEventChoice(option.effect)}
          block
          style={{ marginBottom: 8 }}
        >
          {option.text}
        </Button>
      ))}
    </Modal>
  );
};

export default EventModal;
