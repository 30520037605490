// Dashboard.js
import React from 'react';
import { Card, Statistic, Row, Col, Button, Progress } from 'antd';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import facilityTypes from '../data/facilityTypes'; // Assicurati di usare il percorso corretto per il file facilityTypes.js

const Dashboard = ({ gameState, updateCompany, totalCapacity }) => {
  // Prepara i dati per il grafico
  const energyProductionData = [
    {
      day: gameState.day,
      renewable: gameState.energyProduction.renewable,
      fossil: gameState.energyProduction.fossil,
    },
  ];

  // Calcola la capacità massima totale rinnovabile e fossile
  const totalRenewableCapacity = gameState.facilities
    .filter(facility => facility.type === 'solarPlant')
    .reduce((sum, facility) => sum + (facility.maxCapacity || 0), 0);

  const totalFossilCapacity = gameState.facilities
    .filter(facility => facility.type === 'fossilPlant')
    .reduce((sum, facility) => sum + (facility.maxCapacity || 0), 0);

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card title={`Giorno ${gameState.day}`}>
            <Statistic title="Denaro" value={gameState.money} prefix="€" />
            <Statistic title="Reputazione" value={gameState.reputation} suffix="/ 100" />
            <Progress
              percent={gameState.reputation}
              status={gameState.reputation > 50 ? "active" : "exception"}
              strokeColor={{ '0%': '#ff4d4f', '100%': '#87d068' }}
            />
            <Statistic title="Emissioni" value={gameState.emissions} suffix="/ 100" />
            <Progress
              percent={gameState.emissions}
              status={gameState.emissions < 50 ? "active" : "exception"}
              strokeColor={{ '0%': '#87d068', '100%': '#ff4d4f' }}
            />
            <Statistic title="Punti Ricerca" value={gameState.researchPoints} suffix=" Punti" />
            <Progress
              percent={gameState.researchPoints > 100 ? 100 : gameState.researchPoints}
              status={gameState.researchPoints > 50 ? "active" : "exception"}
              strokeColor={{ '0%': '#ff4d4f', '100%': '#87d068' }}
            />
            <Statistic title="Produzione Rinnovabile" value={gameState.energyProduction.renewable} suffix=" unità" />
            <Statistic title="Capacità Massima Totale" value={totalCapacity} suffix=" unità" />
            <Statistic title="Produzione Fossile" value={gameState.energyProduction.fossil} suffix=" unità" />
            <Statistic title="Capacità Massima Fossile" value={totalFossilCapacity} suffix=" unità" />
            <Button onClick={updateCompany} type="primary" block style={{ marginTop: 16 }}>
              Avanza di un Giorno
            </Button>
          </Card>

          <Card title="Dettagli degli Impianti" style={{ marginTop: 16 }}>
            {gameState.facilities.length > 0 ? (
              gameState.facilities.map((facility, index) => (
                <Card key={index} type="inner" title={facilityTypes[facility.type].name} style={{ marginBottom: 16 }}>
                  <Statistic title="Efficienza" value={facility.efficiency} suffix="%" />
                  <Progress percent={facility.efficiency} status={facility.efficiency > 50 ? "active" : "exception"} />
                  <Statistic title="Capacità Massima" value={facility.maxCapacity} suffix=" unità" />
                </Card>
              ))
            ) : (
              <p>Nessun impianto costruito.</p>
            )}
          </Card>



          <Card title="Contributo del Personale" style={{ marginTop: 16 }}>
            <Statistic title="Boost Efficienza" value={gameState.personnel.reduce((sum, person) => sum + (person.efficiencyBoost || 0), 0)} suffix="%" />
            <Statistic title="Boost Punti Ricerca" value={gameState.personnel.reduce((sum, person) => sum + (person.researchBoost || 0), 0)} suffix=" Punti" />
            <Statistic title="Boost Reputazione" value={gameState.personnel.reduce((sum, person) => sum + (person.reputationBoost || 0), 0)} suffix=" Punti" />
          </Card>
        </Col>

        <Col span={16}>
          <Card title="Produzione Energetica">
            <AreaChart width={600} height={300} data={energyProductionData}>
              <defs>
                <linearGradient id="colorRenewable" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorFossil" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="day" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Area type="monotone" dataKey="renewable" stroke="#82ca9d" fillOpacity={1} fill="url(#colorRenewable)" />
              <Area type="monotone" dataKey="fossil" stroke="#8884d8" fillOpacity={1} fill="url(#colorFossil)" />
            </AreaChart>
          </Card>

          <Card title="Dettagli degli Impianti" style={{ marginTop: 16 }}>
            {gameState.facilities.length > 0 ? (
              gameState.facilities.map((facility, index) => (
                <Card key={index} type="inner" title={facilityTypes[facility.type].name} style={{ marginBottom: 16 }}>
                  <Statistic title="Efficienza" value={facility.efficiency} suffix="%" />
                  <Statistic title="Capacità Massima" value={facility.maxCapacity} suffix=" unità" />
                </Card>
              ))
            ) : (
              <p>Nessun impianto costruito.</p>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
