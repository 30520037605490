import React, { useState } from 'react';
import { Card, Button, Modal, Typography, message } from 'antd';

const PersonnelManagement = ({ gameState, hirePersonnel }) => {
  // Elenco del personale disponibile per l'assunzione
  const [availablePersonnel, setAvailablePersonnel] = useState([
    { id: 1, name: "Ingegnere Energetico", salary: 5000, description: "Migliora l'efficienza degli impianti.", efficiencyBoost: 10, experience: 0, level: 1 },
    { id: 2, name: "Ricercatore", salary: 6000, description: "Aumenta i punti ricerca accumulati.", researchBoost: 5, experience: 0, level: 1 },
    { id: 3, name: "Analista ESG", salary: 7000, description: "Aumenta la reputazione migliorando le metriche ESG.", reputationBoost: 2, experience: 0, level: 1 },
  ]);

  const [selectedPerson, setSelectedPerson] = useState(null);

  const handleHire = (person) => {
    if (gameState.money >= person.salary) {
      hirePersonnel(person);
      // Rimuove il membro del personale dall'elenco disponibile
      setAvailablePersonnel(prev => prev.filter(p => p.id !== person.id));
      message.success(`Hai assunto ${person.name}`);
      setSelectedPerson(null);
    } else {
      message.error("Fondi insufficienti per assumere.");
    }
  };

  return (
    <div>
      <Card title="Personale Disponibile">
        {availablePersonnel.map((person) => (
          <Card key={person.id} type="inner" title={person.name} style={{ marginBottom: 16 }}>
            <Typography.Paragraph>{person.description}</Typography.Paragraph>
            <Typography.Paragraph>Stipendio: €{person.salary.toLocaleString()}</Typography.Paragraph>
            <Button onClick={() => setSelectedPerson(person)} disabled={gameState.money < person.salary}>
              Assumi
            </Button>
          </Card>
        ))}
      </Card>

      <Card title="Personale Assunto" style={{ marginTop: 16 }}>
        {gameState.personnel.length > 0 ? (
          gameState.personnel.map((person, index) => (
            <Card key={index} type="inner" title={person.name} style={{ marginBottom: 16 }}>
              <Typography.Paragraph>Stipendio: €{person.salary.toLocaleString()}</Typography.Paragraph>
              <Typography.Paragraph>Livello: {person.level}</Typography.Paragraph>
              <Typography.Paragraph>Esperienza: {person.experience} punti</Typography.Paragraph>
              {person.efficiencyBoost && (
                <Typography.Paragraph>Bonus Efficienza: {person.efficiencyBoost}%</Typography.Paragraph>
              )}
              {person.researchBoost && (
                <Typography.Paragraph>Bonus Ricerca: {person.researchBoost}</Typography.Paragraph>
              )}
              {person.reputationBoost && (
                <Typography.Paragraph>Bonus Reputazione: {person.reputationBoost}</Typography.Paragraph>
              )}
            </Card>
          ))
        ) : (
          <Typography.Paragraph>Nessun personale assunto.</Typography.Paragraph>
        )}
      </Card>

      {/* Modal per confermare l'assunzione */}
      {selectedPerson && (
        <Modal
          visible={true}
          title={`Assumi ${selectedPerson.name}`}
          onCancel={() => setSelectedPerson(null)}
          onOk={() => handleHire(selectedPerson)}
        >
          <Typography.Paragraph>
            Confermi l'assunzione di {selectedPerson.name} con uno stipendio di €{selectedPerson.salary.toLocaleString()}?
          </Typography.Paragraph>
        </Modal>
      )}
    </div>
  );
};

export default PersonnelManagement;
