import React, { useState, useContext, useEffect } from 'react';
import { Layout, Menu, Button, Tooltip } from 'antd';
import { FaClipboardList, FaHome, FaPlusCircle, FaTrophy } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { TrophyOutlined, CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { AchievementContext } from './AchievementContext';

const { Sider } = Layout;
const { SubMenu } = Menu;

// Bottone mobile toggle per versione scura
const MobileToggle = styled(Button)`
  display: none;

  @media (max-width: 768px) {
    display: block;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
    background-color: #44403c; // Grigio scuro "stone"
    border-color: #44403c;
    color: #f5f5f4; // Testo chiaro
  }
`;

// Bottone "Crea Assessment" con colori scuri
const CreateAssessmentButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #1c1917; // Grigio molto scuro "stone"
  border-color: #1c1917;
  width: 100%;
  font-size: 14px;
  height: 40px;
  color: #f5f5f4; // Testo chiaro
  transition: background-color 0.3s ease, border-color 0.3s ease;
   &:hover {
    background-color: #57534e; /* Effetto hover verde scuro */
  }
`;

function Sidebar() {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { unlockedAchievements } = useContext(AchievementContext);
  const [localAchievements, setLocalAchievements] = useState([]);

  useEffect(() => {
    setLocalAchievements(unlockedAchievements);
  }, [unlockedAchievements]);

  const handleCreateAssessment = () => {
    navigate('/create-assessment');
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const getAchievementIcon = (achievement) => {
    const completionRate = (achievement.value / achievement.maxValue) * 100;
    if (completionRate < 50) {
      return <ClockCircleOutlined style={{ color: 'grey' }} />;
    } else if (completionRate < 75) {
      return <CheckCircleOutlined style={{ color: 'orange' }} />;
    } else {
      return <TrophyOutlined style={{ color: 'gold' }} />;
    }
  };

  return (
    <>
      <MobileToggle icon={collapsed ? <FaHome /> : <FaPlusCircle />} onClick={toggleSidebar} />

      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        breakpoint="md"
        width={250}
        style={{
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          background: '#292524', // Sfondo scuro per la sidebar
          zIndex: 10,
          paddingTop: '20px',
        }}
      >
        <Menu
          mode="inline"
          selectedKeys={[location.pathname]}
          style={{ background: '#292524', color: '#f5f5f4' }} // Testo chiaro
          theme="dark"
        >
          <Menu.Item key="/" icon={<FaHome style={{ color: '#f5f5f4' }} />}> {/* Icone chiare */}
            <Link to="/" style={{ color: '#f5f5f4' }}>Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="/assessment" icon={<FaClipboardList style={{ color: '#f5f5f4' }} />}>
            <Link to="/assessment" style={{ color: '#f5f5f4' }}>Assessment</Link>
          </Menu.Item>
          <SubMenu key="achievements" icon={<FaTrophy style={{ color: '#f5f5f4' }} />} title="Achievements">
            {localAchievements.length > 0 ? (
              localAchievements.map((achievement, index) => (
                <Menu.Item key={`achievement-${index}`}>
                  <Tooltip title={achievement} placement="right">
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {getAchievementIcon(achievement)}
                      <span style={{ marginLeft: '8px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: '#f5f5f4' }}>
                        {achievement}
                      </span>
                    </span>
                  </Tooltip>
                </Menu.Item>
              ))
            ) : (
              <Menu.Item key="no-achievements" disabled>
                Nessun achievement sbloccato
              </Menu.Item>
            )}
          </SubMenu>
        </Menu>

        <div style={{ padding: '0 16px', marginTop: 'auto' }}>
          <CreateAssessmentButton
            type="primary"
            icon={<FaPlusCircle />}
            onClick={handleCreateAssessment}
          >
            Crea Assessment
          </CreateAssessmentButton>
        </div>
      </Sider>
    </>
  );
}

export default Sidebar;
