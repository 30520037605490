import React from 'react';
import { Card, Button, Typography, Progress, message } from 'antd';

const ResearchAndDevelopment = ({ gameState, investInTechnology }) => {
  const technologies = gameState.technologies;

  const handleInvest = (tech) => {
    if (gameState.researchPoints >= tech.cost) {
      investInTechnology(tech); // Chiama la funzione per investire nella tecnologia
    } else {
      message.error("Punti ricerca insufficienti.");
    }
  };
  

  return (
    <div>
      <Card title="Tecnologie Disponibili">
        {technologies.map((tech) => (
          <Card key={tech.id} type="inner" title={tech.name} style={{ marginBottom: 16 }}>
            <Typography.Paragraph>{tech.description}</Typography.Paragraph>
            <Typography.Paragraph>Costo in Punti Ricerca: {tech.cost}</Typography.Paragraph>
            <Progress percent={tech.progress} status={tech.progress < 100 ? "active" : "success"} />
            <Button
              onClick={() => handleInvest(tech)}
              disabled={gameState.researchPoints < tech.cost || tech.progress >= 100}
              style={{ marginTop: 8 }}
            >
              Investi
            </Button>
          </Card>
        ))}
      </Card>
    </div>
  );
};

export default ResearchAndDevelopment;
