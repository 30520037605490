// energyEvents.js
const energyEvents = [
    {
      title: "Normativa sulle Emissioni",
      description: "Il governo introduce nuove normative per ridurre le emissioni di CO₂.",
      options: [
        {
          text: "Investi in tecnologie pulite",
          effect: { money: -200000, emissions: -10, reputation: 10 },
        },
        {
          text: "Ignora le normative e paga la multa",
          effect: { money: -50000, emissions: 5, reputation: -15 },
        },
      ],
    },
    {
      title: "Scoperta di una Nuova Tecnologia",
      description: "Un'innovazione potrebbe aumentare l'efficienza degli impianti solari del 20%.",
      options: [
        {
          text: "Finanzia la ricerca",
          effect: { money: -150000, researchPoints: 20, reputation: 5 },
        },
        {
          text: "Aspetta ulteriori sviluppi",
          effect: { reputation: -5 },
        },
      ],
    },
    {
      title: "Crisi Energetica",
      description: "Una crisi energetica colpisce la città. Come reagisci?",
      options: [
        { text: "Investi in energie rinnovabili", effect: { money: -200000, emissions: -10, energyProduction: { renewable: 10, fossil: -10 } } },
        { text: "Aumenta la produzione di combustibili fossili", effect: { money: 100000, emissions: -10, pollution: 15, energyProduction: { fossil: 20 } } },
        { text: "Implementa misure di risparmio energetico", effect: { social: 5, emissions: 5, happiness: -5 } }
      ]
    },
    // Aggiungi altri eventi se necessario
  ];
  
  export default energyEvents;
  