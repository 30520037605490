const facilityTypes = {
  solarPlant: {
      name: "Impianto Solare",
      cost: 200000,
      maxCapacity: 100, // Aggiunto campo per la capacità massima iniziale
      efficiency: 100, // Efficienza iniziale
      upgradeCost: 100000, // Costo per effettuare l'upgrade
      upgradeBoost: 20, // Aumento di efficienza per ogni upgrade
      effect: {
          energyProduction: { renewable: 50 },
          emissions: -5,
          reputation: 5,
      },
  },
  windFarm: {
      name: "Parco Eolico",
      cost: 300000,
      maxCapacity: 150,
      efficiency: 100,
      upgradeCost: 150000,
      upgradeBoost: 25,
      effect: {
          energyProduction: { renewable: 70 },
          emissions: -7,
          reputation: 7,
      },
  },
  fossilPlant: {
      name: "Centrale a Carbone",
      cost: 150000,
      maxCapacity: 200,
      efficiency: 100,
      upgradeCost: 120000,
      upgradeBoost: 15,
      effect: {
          energyProduction: { fossil: 100 },
          emissions: 15,
          reputation: -10,
      },
  },
  researchLab: {
      name: "Laboratorio di Ricerca",
      cost: 250000,
      maxCapacity: 0, // Non produce energia, quindi capacità è zero
      efficiency: 100,
      upgradeCost: 80000,
      upgradeBoost: 10,
      effect: {
          researchPoints: 10,
          reputation: 5,
      },
  },
  batteryStorage: {
      name: "Impianto di Stoccaggio",
      cost: 200000,
      maxCapacity: 100,
      efficiency: 100,
      upgradeCost: 90000,
      upgradeBoost: 15,
      effect: {
          energyStorage: 100,
          reputation: 3,
      },
  },
};

export default facilityTypes;
